import {isMobile} from 'react-device-detect';
import styled, { css } from 'styled-components';
import { colorTheme } from '../../../../core/configs';
import homeownerBg from '../../../../assets/homeowner-bg.webp';
import kasambahayBG from '../../../../assets/kasambahay-bg.webp';
import { isUsingMobileBrowser } from '../../../utils/random.util';

export const StyledHowAppWorksWrapper = styled.div`
  ${isUsingMobileBrowser()
    ? css`
        height: calc(100dvh - 55px - env(safe-area-inset-top));
        gap: 16px;
      `
    : isMobile
    ? css`
        height: calc(100vh - 55px - env(safe-area-inset-top));
        gap: 16px;
      `
    : css`
        height: calc(100vh - 65px);
        gap: 48px;
      `}
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 36px 24px 36px 24px;

  img {
    width: ${isUsingMobileBrowser() ? '210px' : isMobile ? '75%' : '305px'} !important;
  }

  .carousel.carousel-slider {
    overflow: unset;
    height: 100%;
  }

  .carousel .control-dots {
    display: flex;
    justify-content: center;
  }

  .carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
    background-color: #998CFF;
    border-color: #998CFF;
  }

  .carousel .control-dots .dot {
    opacity: 1;
    width: 12px;
    height: 12px;
    background-color: #E2E2E2;
    opacity: 1;
    border: 1px solid #E2E2E2;
    border-radius: 50%;
    content: '';
    box-shadow: none; 
  }

  .slider-wrapper, .slider {
    height: 100%;
  }

  .carousel .slider-wrapper.axis-horizontal .slider .slide {
    display: flex;
    align-items: center;
    padding-bottom: 48px;
  }
`;

export const StyledHeader = styled.div`
  ${isMobile
    ? css`
        font-size: 1.25rem;
      `
    : css`
        font-size: 1.953rem;
      `}

  letter-spacing: 0rem;
  font-weight: 700;
  text-align: center;
  color: ${colorTheme.primary};
`;

export const StyledImgDesc = styled.h4`
  ${isMobile
    ? css`
        font-size: 0.875rem;
      `
    : css`
        font-size: 2rem;
      `}

  letter-spacing: 0rem;
  font-weight: 700;
  text-align: center;
  color: ${colorTheme.accent};
`;
