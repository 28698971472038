import {Switch, Route, Redirect} from 'react-router-dom';
import SignUpWizard from '../../pages/auth/sign-up/SignUpWizard';
import EmployerSignUpWizard from '../../pages/auth/sign-up/EmployerSignUpWizard';
import FinderSettingsPage from '../../pages/finder-settings/FinderSettingsPage';
import LandingPage from '../../pages/landing/LandingPage';
import TaCPage from '../../pages/tac/TaCPage';
import NotFound from '../../views/not-found/NotFound';
import KazamUserProfilePage from '../../pages/user-profile/kazam/KazamUserProfilePage';
import HomeownerUserProfilePage from '../../pages/user-profile/homeowner/HomeownerUserProfilePage';
import EditKazamUserProfilePage from '../../pages/user-profile/kazam/EditKazamUserProfilePage';
import EditHomeownerUserProfilePage from '../../pages/user-profile/homeowner/EditHomeownerUserProfilePage';
import ProfileSetupPage from '../../pages/user-profile/kazam/ProfileSetupPage';
import EditProfileSetupPage from '../../pages/user-profile/kazam/EditProfileSetupPage';
import {RoutePath} from './../config/RouteConfig';
import SignInPage from '../../pages/auth/sign-in/SignInPage';
import PrivacyPolicyPage from '../../pages/privacy-policy/PrivacyPolicyPage';
import DefaultFinderSettingsPage from '../../pages/finder-settings/DefaultFinderSettingsPage';
import KazamFilterSettings from '../../pages/finder-settings/KazamFilterSettings';
import {useAuth} from '../../hooks/useAuth';
import JobSeekerProfileViewPage from '../../pages/jobseeker-profile-view/JobSeekerProfileViewPage';
import EmployerProfileViewPage from '../../pages/employer-profile-view/EmployerProfileViewPage';
import LogoutPage from '../../pages/logout/LogoutPage';
import ChatPage from '../../pages/chat/ChatPage';
import ChatRoomPage from '../../pages/chat-room/ChatRoomPage';
import ChatPageTest from '../../pages/chat-room/ChatPageTest';
import ScrollToTop from '../utils/ScrollToTop';
import FavoriteView from '../../pages/favorite-view/FavoriteView';
import KazamNotificationsPage from '../../pages/notifications/KazamNotificationsPage';
import Notifications from '../../components/notifications/Notifications';
import PushNotificationsListener from '../../components/notifications/PushNotificationsListener';
import AboutUs from '../../pages/about-us/AboutUs';
import firebase from 'firebase/app';
import {useToast} from '../../../core/components/toast';
import {MatchesProvider} from '../../hooks/useMatches';
import AdminRouter from './AdminRouter';
import AdminSignInPage from '../../pages/admin/sign-in/AdminSignInPage';
import ChatFilterPage from '../../pages/chat-filter/ChatFilterPage';
import {useEffect, useState} from 'react';
import {useGetAdminControls} from '../../server/react-query';
import PasswordResetWizard from '../../pages/auth/password-reset/PasswordResetWizard';
import SettingsPage from '../../pages/settings/SettingsPage';
import LanguageSettingsPage from '../../pages/settings/LanguageSettingsPage';
import NotificationSettingsPage from '../../pages/settings/NotificationSettingsPage';
import {localization} from '../../localization/Localization';
import ReferralLandingPage from '../../pages/referral/ReferralLandingPage';
import DeleteAccountPage from '../../pages/user-profile/account/DeleteAccountPage';
import DisableAccountPage from '../../pages/user-profile/account/DisableAccountPage';
import DisabledAccountLandingPage from '../../pages/user-profile/account/DisabledAccountLandingPage';
import {UserResponse} from '../../server/types';
import {useAnalytics} from '../../hooks/useAnalytics';
import {useLocation} from 'react-router';
import StatusUpdater from '../../components/status-updater/StatusUpdater';
import VersionInfoChecker from '../../components/version-info-checker/VersionInfoChecker';
import MatchingLandingPage from '../../pages/match-landing-page';
import ChangePasswordPage from '../../pages/user-profile/account/ChangePasswordPage';
import AppUrlListener from '../../listeners/AppUrlListener';
import PaymentSuccessRedirection from '../../pages/paywall/payment-success-redirection/PaymentSuccessRedirection';
import SubscriptionHistoryPage from '../../pages/settings/subscription/SubscriptionHistoryPage';
import SubscriptionDetailsPage from '../../pages/settings/subscription/SubscriptionDetailsPage';
import VerificationPage from '../../pages/verification/VerificationPage';
import VideoCallRoomPage from '../../pages/call-room/VideoCallRoomPage';
import VoiceCallRoomPage from '../../pages/call-room/VoiceCallRoomPage';
import { isUsingApp } from '../../utils/random.util';
import CurrentLocation from '../../pages/current-location/CurrentLocation';
import ViewCurrentLocation from '../../pages/current-location/ViewCurrentLocation';
import PurchaseSummaryPage from '../../pages/paywall/purchase-summary/PurchaseSummaryPage';
import HistoryPage from '../../pages/history/HistoryPage';
import TakePersonalityTest from '../../pages/personality-questionnaire/TakePersonalityTest';
import PersonalityTestWizard from '../../pages/personality-questionnaire/PersonalityTestWizard';
import PersonalityTestSuccess from '../../pages/personality-questionnaire/PersonalityTestSuccess';

const usePageViews = () => {
  const {pathname} = useLocation();
  const {systemLogEvent} = useAnalytics();

  const [recentPathname, setRecentPathname] = useState<string>('');

  useEffect(() => {
    systemLogEvent('screen_view', {
      firebase_screen: pathname,
      firebase_screen_class: '',
    });

    if (pathname) {
      if (!sessionStorage.getItem('session_id')) {
        systemLogEvent('session');

        sessionStorage.setItem('session_id', new Date().toString());
      }
    }

    setRecentPathname(prevState => {
      if (prevState) {
        // Page's session end
        systemLogEvent('page_session_end', {
          page: prevState,
          timestamp: new Date().getTime(),
        });
      }

      // Page's session start
      systemLogEvent('page_session_start', {
        page: pathname,
        timestamp: new Date().getTime(),
      });
      return pathname;
    });
  }, [pathname]);
};

const MainRoute = () => {
  const {authData} = useAuth();
  const {data} = useGetAdminControls();

  const [enableKaway, setEnableKaway] = useState(true);
  const [currentUser, setCurrentUser] = useState<UserResponse | undefined>(
    authData?.user ?? undefined,
  );
  const [isHomeowner, setIsHomeowner] = useState(
    authData?.user?.userRole === 'homeowner',
  );
  const [isKazam, setIsKazam] = useState(authData?.user?.userRole === 'kazam');
  const [isFromEmail, setIsFromEmail] = useState(false);

  useEffect(() => {
    var fromEmail = localStorage.getItem('fromEmail');
    if (fromEmail) {
      setIsFromEmail(true);
    } else {
      setIsFromEmail(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      var enableKawayControl = data.find(d => d.type === 'enableKaway');
      if (enableKawayControl) {
        setEnableKaway(enableKawayControl.enabled);
      }
      localStorage.setItem('adminData', JSON.stringify(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (authData && authData.user) {
      setCurrentUser(authData.user);
      setIsHomeowner(authData.user.userRole === 'homeowner');
      setIsKazam(authData.user.userRole === 'kazam');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authData]);

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={RoutePath.MATCHES_LANDING}></Redirect>
      </Route>

      <Route path={RoutePath.MATCHES_LANDING}>
        {currentUser &&
        isHomeowner &&
        (!currentUser.filterSetting ||
          (currentUser.filterSetting &&
            currentUser.filterSetting.kazamRoles.length === 0)) ? (
          <Redirect to={RoutePath.DEFAULT_FINDER_SETTINGS} />
        ) : currentUser && isKazam && !currentUser.filterSetting ? (
          <Redirect to={RoutePath.KAZAM_FILTER_SETTINGS} />
        ) : isKazam && !enableKaway ? (
          <Redirect to={RoutePath.CHAT} />
        ) : currentUser && currentUser?.userStatus === 'disabled' ? (
          <Redirect to={RoutePath.DISABLED_LANDING} />
        ) : currentUser && isKazam && isFromEmail ? (
          <Redirect to={RoutePath.NOTIFICATIONS} />
        ) : currentUser && isHomeowner && isFromEmail ? (
          <Redirect to={RoutePath.KAWAYS} />
        ) : (
          <MatchesProvider>
            <MatchingLandingPage />
          </MatchesProvider>
        )}
      </Route>

      <Route path={RoutePath.DISABLED_LANDING}>
        <DisabledAccountLandingPage />
      </Route>

      <Route path={RoutePath.FINDER_SETTINGS}>
        <FinderSettingsPage />
      </Route>
      <Route exact path={RoutePath.MY_PROFILE}>
        {isKazam ? (
          <Redirect to={RoutePath.MY_PROFILE_KZ} />
        ) : (
          <Redirect to={RoutePath.MY_PROFILE_HO} />
        )}
      </Route>
      <Route exact path={RoutePath.MY_PROFILE_KZ}>
        <KazamUserProfilePage />
      </Route>
      <Route exact path={RoutePath.MY_PROFILE_HO}>
        <HomeownerUserProfilePage />
      </Route>
      <Route exact path={RoutePath.EDIT_MY_PROFILE}>
        {isKazam ? (
          <Redirect to={RoutePath.EDIT_MY_PROFILE_KZ} />
        ) : (
          <Redirect to={RoutePath.EDIT_MY_PROFILE_HO} />
        )}
      </Route>
      <Route exact path={RoutePath.EDIT_MY_PROFILE_KZ}>
        <EditKazamUserProfilePage />
      </Route>
      <Route exact path={RoutePath.EDIT_MY_PROFILE_HO}>
        <EditHomeownerUserProfilePage />
      </Route>
      <Route exact path={RoutePath.PROFILE_SETUP}>
        <ProfileSetupPage />
      </Route>
      <Route exact path={RoutePath.EDIT_PROFILE_SETUP}>
        <EditProfileSetupPage />
      </Route>

      <Route exact path={RoutePath.NOTIFICATIONS}>
        <KazamNotificationsPage />
      </Route>

      <Route exact path={RoutePath.NOTIFICATION_SETTINGS}>
        <NotificationSettingsPage />
      </Route>

      <Route exact path={RoutePath.SUBSCRIPTION_DETAILS}>
        <SubscriptionDetailsPage />
      </Route>

      <Route exact path={RoutePath.SUBSCRIPTION_HISTORY}>
        <SubscriptionHistoryPage />
      </Route>

      <Route exact path={RoutePath.DISABLE_ACCOUNT}>
        <DisableAccountPage />
      </Route>

      <Route exact path={RoutePath.DELETE_ACCOUNT}>
        <DeleteAccountPage />
      </Route>

      <Route exact path={RoutePath.CHANGE_ACCOUNT_PASSWORD}>
        <ChangePasswordPage />
      </Route>

      <Route exact path={RoutePath.SUCCESSFUL_PAYMENT_REDIRECTION}>
        <PaymentSuccessRedirection />
      </Route>

      <Route exact path={RoutePath.GET_VERIFIED}>
        <VerificationPage />
      </Route>

      <Route exact path={RoutePath.TAKE_PERSONALITY_TEST}>
        <TakePersonalityTest />
      </Route>

      <Route exact path={RoutePath.PERSONALITY_TEST}>
        <PersonalityTestWizard />
      </Route>

      <Route exact path={RoutePath.PERSONALITY_TEST_SUCCESS}>
        <PersonalityTestSuccess />
      </Route>

      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};

const NavigationRouter = () => {
  usePageViews();
  const {authData} = useAuth();
  const {presentToast} = useToast();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const [fromEmail] = useState<string>(params.get('fromEmail') || '');

  useEffect(() => {
    if (fromEmail != '') {
      localStorage.setItem('fromEmail', fromEmail);
    }
  }, [fromEmail]);

  if (firebase.messaging.isSupported()) {
    firebase.messaging().onMessage(payload => {
      console.log('notification received: ', payload);

      presentToast({
        title: payload.data.title,
        message: payload.data.body,
        notificationType: payload.data.type,
        notifFromUserId: payload.data.fromUserId,
        variant: 'notification',
        position: 'top',
      });
    });
  }

  useEffect(() => {
    var language = localStorage.getItem('language');
    if (language) {
      localization.setLanguage(language);
    } else {
      localStorage.setItem('language', 'en');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-full w-full flex flex-row justify-center">
      <div className="h-full w-full">
        <ScrollToTop />
        <Switch>
          <Route exact path="/">
            <Redirect
              to={
                authData
                  ? authData.user && authData.user.userRole !== 'admin'
                    ? RoutePath.MATCHES_LANDING
                    : RoutePath.ADMIN_LANDING
                  : RoutePath.LANDING
              }
            />
          </Route>

          <Route path={RoutePath.SIGN_IN}>
            {authData ? (
              <Redirect to={RoutePath.MATCHES_LANDING} />
            ) : (
              <SignInPage />
            )}
          </Route>

          <Route path={RoutePath.LANDING}>
            {authData ? (
              <Redirect to={RoutePath.MATCHES_LANDING} />
            ) : (
              <LandingPage />
            )}
          </Route>

          <Route path={RoutePath.SIGN_UP}>
            <SignUpWizard />
          </Route>

          <Route path={RoutePath.EMPLOYER_SIGN_UP}>
            <EmployerSignUpWizard />
          </Route>

          <Route path={RoutePath.PASSWORD_RESET}>
            <PasswordResetWizard />
          </Route>

          <Route path={RoutePath.TERMS_AND_CONDITIONS}>
            <TaCPage />
          </Route>

          <Route path={RoutePath.PRIVACY_POLICY}>
            <PrivacyPolicyPage />
          </Route>

          <Route path={RoutePath.ABOUT_US}>
            <AboutUs />
          </Route>

          <Route exact path={RoutePath.SETTINGS}>
            <SettingsPage />
          </Route>

          <Route exact path={RoutePath.HISTORY}>
            <HistoryPage />
          </Route>

          <Route exact path={RoutePath.LANGUAGE_SETTINGS}>
            <LanguageSettingsPage />
          </Route>

          <Route exact path={RoutePath.JOB_SEEKER_PROFILE_VIEW}>
            <JobSeekerProfileViewPage />
          </Route>

          <Route exact path={RoutePath.FAVORITE_VIEW}>
            <FavoriteView />
          </Route>

          <Route exact path={RoutePath.EMPLOYER_PROFILE_VIEW}>
            <EmployerProfileViewPage />
          </Route>

          <Route exact path={RoutePath.CHAT}>
            <ChatPage />
          </Route>

          <Route exact path={RoutePath.CHAT_FILTER}>
            <ChatFilterPage />
          </Route>

          <Route path={RoutePath.REFERRAL_LANDING}>
            <ReferralLandingPage />
          </Route>

          <Route exact path={RoutePath.FAVORITES}>
            <ChatPage index={1} />
          </Route>

          <Route exact path={RoutePath.KAWAYS}>
            <ChatPage index={2} />
          </Route>

          <Route exact path={RoutePath.CHAT_ROOM}>
            {/* <ChatRoomPage /> */}
            <ChatPageTest />
          </Route>

          <Route exact path={RoutePath.VIDEO_CALL_ROOM}>
            <VideoCallRoomPage />
          </Route>

          <Route exact path={RoutePath.VOICE_CALL_ROOM}>
            <VoiceCallRoomPage />
          </Route>

          <Route exact path={RoutePath.LOGOUT}>
            <LogoutPage />
          </Route>

          <Route exact path={RoutePath.DEFAULT_FINDER_SETTINGS}>
            <DefaultFinderSettingsPage />
          </Route>

          <Route exact path={RoutePath.KAZAM_FILTER_SETTINGS}>
            <KazamFilterSettings />
          </Route>

          <Route path={RoutePath.ADMIN_SIGN_IN}>
            <AdminSignInPage />
          </Route>

          <Route exact path={RoutePath.CURRENT_LOCATION}>
            <CurrentLocation />
          </Route>

          <Route exact path={RoutePath.VIEW_CURRENT_LOCATION}>
            <ViewCurrentLocation />
          </Route>

          <Route exact path={RoutePath.SAVE_CURRENT_LOCATION}>
            <CurrentLocation editProfile/>
          </Route>

          <Route exact path={RoutePath.SELECT_CURRENT_LOCATION}>
            <CurrentLocation editProfile localSave/>
          </Route>

          <Route exact path={RoutePath.PURCHASE_SUMMARY}>
            <PurchaseSummaryPage/>
          </Route>

          {authData ? (
            authData.user && authData.user.userRole !== 'admin' ? (
              <MainRoute />
            ) : (
              <AdminRouter />
            )
          ) : (
            <Redirect to={RoutePath.LANDING} />
          )}

          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
        {authData && <StatusUpdater />}
        {authData && <Notifications />}
        {authData
          && isUsingApp()
          && <PushNotificationsListener />}
        {!authData && <VersionInfoChecker />}
        <AppUrlListener />
      </div>
    </div>
  );
};

export default NavigationRouter;
