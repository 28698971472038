import {QueryClient, QueryClientProvider} from 'react-query';
import './App.css';
import {AuthProvider} from './app/hooks/useAuth';
import {NavigationRouter} from './app/navigation';
import {ComponentProvider} from './core/components';
import {AnalyticsProvider} from './app/hooks/useAnalytics';
import ZoomVideo from '@zoom/videosdk';
import ZoomContext from './app/contexts/zoom-context';
import {useEffect} from 'react';

const queryClient = new QueryClient();
const zoomClient = ZoomVideo.createClient();

const App = () => {
  // useEffect(() => {
  //   const init = async () => {
  //     await zoomClient.init('en-US', 'Global', {
  //       patchJsMedia: true,
  //       leaveOnPageUnload: true,
  //       stayAwake: true,
  //       enforceMultipleVideos: true,
  //     });
  //   };
  //   init();
  //   return () => {
  //     ZoomVideo.destroyClient();
  //   };
  // }, [zoomClient]);

  return (
    <QueryClientProvider client={queryClient}>
      <div className="h-screen w-screen overflow-x-hidden">
        <AuthProvider>
          <AnalyticsProvider>
            <ZoomContext.Provider value={zoomClient}>
              <ComponentProvider>
                <NavigationRouter />
              </ComponentProvider>
            </ZoomContext.Provider>
          </AnalyticsProvider>
        </AuthProvider>
      </div>
    </QueryClientProvider>
  );
};

export default App;
