import React, {useCallback, useEffect, useReducer, useRef, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {
  useAcceptDisclaimer,
  useGetConversationById,
  useGetConversationContent,
  usePostMarkAllMessagesAsRead,
  usePostMarkConversationContentAsRead,
} from '../../../server/react-query';
import MyChatMessage from '../../../views/cards/my-chat-message/MyChatMessage';
import RecipientChatMessage from '../../../views/cards/recipient-chat-message/RecipientChatMessage';
import {ChatContentResponse, UserResponse} from '../../../server/types';
import {useAuth} from '../../../hooks/useAuth';
import {RoutePath} from '../../../navigation/config/RouteConfig';
import {ConversationClient} from '../../../../conversation-client';
import {
  StyledChatScrollable,
  StyledChatTest,
} from './styled';

import {useUploadChatMedia} from '../../../server/react-query';
import {useDialog} from '../../../../core/components/dialog';
import DisclaimerDialog from '../../../views/dialog-content/disclaimer/DisclaimerDialog';
import {Action} from 'history';
import {useAnalytics} from '../../../hooks/useAnalytics';
import {useToast} from '../../../../core/components/toast';
import {useQueryClient} from 'react-query';
import {Button, LoadMore, Typography} from '../../../../core/components';
import {BeatLoader} from 'react-spinners';
import {colorTheme} from '../../../../core/configs';
import AdminPage from '../../../../core/components/admin-page/AdminPage';
import ChatProfileView from './chat-profile/ChatProfileView';
import ChatText from './chat-text/ChatText';
import format from 'date-fns/format';

interface ContentFetchParams {
  page: number
}

interface AdminChatRoomPageParams {
  id: string;
}

const sortConversation = (
  data: ChatContentResponse[],
): ChatContentResponse[] => {
  return data.sort(
    (a: ChatContentResponse, b: ChatContentResponse) =>
      Date.parse(a.createdOn) - Date.parse(b.createdOn),
  );
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'SET_PAGE':
      return {
        ...state,
        page: action.payload.page
      }
    case 'REFETCH_LATEST':
      return {
        ...state,
        page: 1
      }
    default:
      return state
  }
}

const AdminChatRoomPage = () => {
  const history = useHistory();
  const {systemLogEvent} = useAnalytics();
  const {presentDialog, dismissDialog} = useDialog();
  const {authData, updateAuthData} = useAuth();
  const {presentToast} = useToast();
  const queryClient = useQueryClient();
  const location = useLocation();

  const {id: channelId} = useParams<AdminChatRoomPageParams>();
  const params = new URLSearchParams(location.search);
  const [reporter] = useState<string>(params.get('reporter') || '');

  const {data, refetch} = useGetConversationById(channelId);
  const [recipient, setRecipient] = useState<UserResponse>();
  const [isHomeowner, setIsHomeowner] = useState(false);
  const [conversationClient, setConversationClient] = useState<
    ConversationClient | undefined
  >(undefined);
  const [conversationContents, setConversationContents] = useState<
    ChatContentResponse[] | undefined
  >(undefined);
  const [userId, setUserId] = useState<string | undefined>(
    authData?.user.userId,
  );
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isConnectedToHub, setIsConnectedToHub] = useState<boolean>(false);
  const [isJoined, setIsJoined] = useState<boolean>(false);
  const [uploadedUri, setUploadedUri] = useState<string | undefined>('');
  const [attachmentName, setAttachmentName] = useState<string | undefined>('');
  const [newReceivedMessage, setNewReceivedMessage] = useState<
    ChatContentResponse | undefined
  >(undefined);
  const [isAllMessagesFetched, setIsAllMessagesFetched] = useState<boolean>(
    false,
  );
  const [isScrollable, setIsScrollable] = useState<boolean>(true);
  const [accessDenied, setAccessDenied] = useState<boolean>(false);

  const [scrollTopValue, setScrollTopValue] = useState<number>(0);
  const [isContentReady, setIsContentReady] = useState<boolean>(false);
  const [chatSessionId] = useState<string>(new Date().getTime().toString());
  const [latestReadContent, setLatestReadContent] = useState<
    ChatContentResponse | undefined
  >(undefined);

  const INITIAL_PARAMS: ContentFetchParams = {
    page: 1
  }
  const [contentParams, dispatchSearch] = useReducer(reducer, INITIAL_PARAMS)

  const {
    data: hookDataConversationContent,
    refetch: refetchConversations,
    isFetching,
    error: conversationContentError
  } = useGetConversationContent(channelId, contentParams.page);
  
  useEffect(() => {
    refetchConversations()
  }, [contentParams]);

  const refetchLatestConversationContents = () => {
    dispatchSearch({
      type: 'REFETCH_LATEST'
    });
  }

  useEffect(() => {
    if (conversationContentError) {
      const error = conversationContentError as any;
      if (error?.status === 403) setAccessDenied(true)
    } else {
      setAccessDenied(false)
    }
  }, [conversationContentError])

  useEffect(() => {
    if (!isAllMessagesFetched) {
      (async () => {
        await refetchConversations();
      })();
    } else {
      console.log('All messages have been fetched');
    }
  }, [pageNumber]);

  useEffect(() => {
    if (conversationContents && contentParams?.page === 1) {
      scrollToBottom();
    }
  }, [conversationContents]);

  const postMarkAllMessagesAsRead = usePostMarkAllMessagesAsRead(channelId);

  const postMarkConversationContentAsRead = usePostMarkConversationContentAsRead(
    {
      conversationContentIds: [newReceivedMessage?.conversationContentId || ''],
    },
  );

  const listenToEvents = useCallback(() => {
    conversationClient!.on('connecting', () => {
      // console.log('connecting');
      setIsJoined(false);
      setIsConnectedToHub(false);
    });

    conversationClient!.on('connected', (connectionId: string | null) => {
      // console.log('connected', {connectionId});
      setIsConnectedToHub(true);
      scrollToBottom();
    });

    conversationClient!.on('disconnected', (error: Error | undefined) => {
      console.log('disconnected', {error});
      setConversationClient(undefined);
      setIsJoined(false);
      setIsConnectedToHub(false);
    });

    conversationClient!.on('messageReceive', async (data: string) => {
      systemLogEvent('chat_message_received', {session_id: chatSessionId});

      let content: ChatContentResponse;
      try {
        content = JSON.parse(data) as ChatContentResponse;
      } catch (e) {
        console.log(e);
        return;
      }

      // Check for attachments
      if (content.media && content.media.length > 0) {
        systemLogEvent('chat_message_with_attachment_received', {
          session_id: chatSessionId,
        });
      }

      setConversationContents(prevState =>
        sortConversation([...(prevState || []), content]),
      );
      setNewReceivedMessage(content);
      scrollToBottom();

      // Invalidate cache
      queryClient.removeQueries([`get-conversation-content`, channelId]);
    });

    conversationClient!.on('reFetchContents', async (data: string) => {
      console.log("reFetchContents");
      refetchLatestConversationContents()
      queryClient.invalidateQueries([`get-conversation-by-id`, channelId]);
    });
  }, [conversationClient]);

  useEffect(() => {
    // Every time the user opens the chat room page is consider a new session
    systemLogEvent('unique_chat_session', {session_id: chatSessionId});

    (async () => {
      await postMarkAllMessagesAsRead.mutateAsync();
    })();

    return () => {
      // setConversationContents(undefined);
    };
  }, []);

  useEffect(() => {
    if (newReceivedMessage && newReceivedMessage.userId !== userId) {
      // mark message as read
      (async () => {
        await postMarkConversationContentAsRead.mutateAsync();
        conversationClient?.triggerRefetchContents();
        // console.log('Message marked as read');
      })();
    }
  }, [userId, newReceivedMessage]);

  useEffect(() => {
    if (conversationClient && isConnectedToHub && userId) {
      // Join conversation
      (async () => {
        const joinSuccessful = await conversationClient.join(userId);
        setIsJoined(joinSuccessful);
      })();
      conversationClient?.triggerRefetchContents()
    }
  }, [conversationClient, isConnectedToHub, userId]);

  useEffect(() => {
    let timeoutRef: NodeJS.Timeout;
    if (isContentReady) {
      timeoutRef = setTimeout(() => {
        scrollToBottom();
      }, 200);
    }
    return () => {
      timeoutRef && clearTimeout(timeoutRef);
    };
  }, [isContentReady]);

  useEffect(() => {
    if (hookDataConversationContent) {
      setIsContentReady(true);
      if (hookDataConversationContent.length > 0) {
        let newContents: ChatContentResponse[] = [];
        let oldData = [...conversationContents ?? []]
        if (conversationContents) {
          for (let newData of hookDataConversationContent) {
            const isFound = !!conversationContents.find(
              oldData =>
                oldData.conversationContentId === newData.conversationContentId,
            );
            
            if (!isFound) {
              newContents.push(newData);
            } else {
              if (!!newData.meta
                || !!newData.readOn
              ) {
                let index = oldData.findIndex(c => c.conversationContentId == newData.conversationContentId)
                oldData[index].meta = newData.meta
                oldData[index].readOn = newData.readOn
              }
            }
          }
        } else {
          newContents = hookDataConversationContent;
        }
        
        if (hookDataConversationContent.length === 10) {
          setIsAllMessagesFetched(false);
        } else {
          setIsAllMessagesFetched(true);
        }
        
        var latestContents = sortConversation([oldData || [], newContents].flat())
        setConversationContents(latestContents)
        setLatestReadContentResponse(latestContents ?? [])
      } else {
        setIsAllMessagesFetched(true);
      }
    }
  }, [hookDataConversationContent, pageNumber]);

  useEffect(() => {
    if (conversationClient) {
      listenToEvents();
      (async () => {
        // Connect to hub
        await conversationClient.create(channelId);
      })();
    }
  }, [conversationClient, channelId, listenToEvents]);

  useEffect(() => {
    if (!conversationClient) {
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const convoClient = new ConversationClient({
        url: `${baseUrl}conversation-hub`,
        logLevel: 'none',
      });
      setConversationClient(convoClient);
    }
    return () => {
      if (conversationClient) {
        (async () => {
          await conversationClient.stop();
        })();
      }
    };
  }, [conversationClient]);

  useEffect(() => {
    if (data) {
      const recipient = data.members.find(m => m.userId !== userId);
      setRecipient(recipient?.user);
    }
  }, [data, userId]);

  useEffect(() => {
    if (authData) {
      setIsHomeowner(authData.user.userRole === 'homeowner');
      setUserId(authData.user.userId);

      if (!authData.user.lastAcceptedChatDisclaimer) {
        showDisclaimerDialog();
      }
    }
  }, [authData]);

  useEffect(() => {
    if (!!reporter) {
      setUserId(reporter)
    }
  }, [reporter])

  const showDisclaimerDialog = () => {
    presentDialog({
      headerText: '',
      content: (
        <DisclaimerDialog
          onCancel={cancelDisclaimer}
          onContinue={acceptDisclaimer}
        />
      ),
      enableBackdropDismiss: false,
      hideClose: true,
    });
  };

  const cancelDisclaimer = () => {
    dismissDialog();
    if (history.action === Action.Push) {
      history.go(-1); //.back() will throw an error
    } else {
      // No more previous page; replace to home
      history.replace('/');
    }
  };

  const acceptDisclaimerMutation = useAcceptDisclaimer();
  const acceptDisclaimer = async () => {
    var user = await acceptDisclaimerMutation.mutateAsync();
    if (user) {
      let data = authData;
      if (data) {
        data.user = user;
        updateAuthData(data);
      }
    }
    dismissDialog();
  };

  const onSendMessage = async (message: string) => {
    if (conversationClient && userId && isJoined) {
      await conversationClient.send(userId, message);
    }
  };

  const uploadMedia = useUploadChatMedia();
  const onSendMedia = async (file: any | null) => {
    try {
      if (!file.type.includes('image')) {
        setUploadedUri(undefined);
        setAttachmentName(file.name);
      } else {
        setAttachmentName(undefined);
      }

      var content = await uploadMedia.mutateAsync({
        file: file,
        channelId: channelId,
      });
      if (content && conversationClient && userId) {
        setUploadedUri(undefined);
        await conversationClient.sendMedia(userId, content);
      }
    } catch (e: any) {
      setUploadedUri(undefined);
      setAttachmentName(undefined);
      presentToast({
        message: e.data.error,
        variant: 'error',
        position: 'bottom',
      });
    }
  };

  useEffect(() => {
    dispatchSearch({
      type: 'SET_PAGE',
      payload: {
        page: pageNumber
      },
    });
  }, [pageNumber])

  const chatContainer = useRef<HTMLDivElement>(null);
  const scrollToElement = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (scrollToElement.current) {
      scrollToElement.current.scrollIntoView({behavior: 'smooth'});
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLElement>): void => {
    e.stopPropagation(); // Handy if you want to prevent event bubbling to scrollable parent
    setScrollTopValue(e.currentTarget.scrollTop);
  };

  const onLoadPrevMessages = () => {
    setPageNumber(prevState => prevState + 1);
  }

  const onEndConversation = async () => {
    if (conversationClient) {
      await conversationClient.endConversation();
      refetch();
    }
  };

  const onEnableConversation = async () => {
    if (conversationClient) {
      await conversationClient.enableConversation();
      refetch();
    }
  };

  const handleBack = () => {
    history.go(-1);
  };

  const callHandler = (callType: string) => {
    onSendMessage(`KAZAM_SYSTEM_${callType.toUpperCase()}_CALL`);
  };

  const setLatestReadContentResponse = (data: ChatContentResponse[]) => {
    const hasReadOn = data?.some(item => item?.readOn);
    if(hasReadOn){
      const mySentMessages = data?.filter(c => c.user.userId === authData?.user.userId
        && !!c.readOn
      )
      
      if (mySentMessages.length > 0) {
        const latestRead = mySentMessages[mySentMessages.length - 1]
        if (latestRead) setLatestReadContent(latestRead)
      }
    }
  };

  return (
    <AdminPage unPadded chatRoom>
      {accessDenied ? (
        <div className="h-full w-full flex flex-col items-center justify-center text-secondary space-y-2">
          <Typography
            label="You have no access to this conversation."
            variant="f2"
            color={colorTheme.dark}
          />
          <div>
            <Button
              label="Go Back"
              color='primary'
              onClick={handleBack}
            />
          </div>
        </div>
        
      ): (
        <StyledChatTest>
          {isContentReady && isConnectedToHub && 
            <div className="header">
              <div className="profile">
                {recipient && (
                  <ChatProfileView
                    channelId={channelId}
                    channelStatus={data?.status}
                    profile={recipient}
                    hideMenu={!!reporter}
                    onEndConversation={onEndConversation}
                    onEnableConversation={onEnableConversation}
                    onCallClick={callHandler}
                />
                )}
              </div>

              <div className="profile"></div>
            </div>
          }

          {recipient && (
            <div className="content chat-content-wrapper">
              <StyledChatScrollable
                className="chats space-y-4"
                ref={chatContainer}
                onScroll={handleScroll}
                isNotScrollable={!isScrollable}>
                {!isAllMessagesFetched &&  isConnectedToHub &&
                  <div className="mt-4">
                    <LoadMore isFetching={isFetching} onClick={onLoadPrevMessages}/>
                  </div>
                }
                
                {isContentReady && isConnectedToHub ? (
                  <div className="p-6 space-y-2">
                  {(conversationContents || []).map(chatContent => {
                      if (chatContent.userId === userId) {
                        // My message
                        return (
                          <>
                            <MyChatMessage
                              key={chatContent.conversationContentId}
                              message={chatContent.content}
                              date={new Date(chatContent.createdOn)}
                              media={
                                chatContent.media.length > 0
                                  ? chatContent.media[0]
                                  : undefined
                              }
                              meta={chatContent?.meta}
                            />
                            {latestReadContent
                              && latestReadContent.readOn
                              && latestReadContent.conversationContentId === chatContent.conversationContentId &&
                              <Typography 
                                label={`Seen ${format(new Date(latestReadContent.readOn), 'PP')} ${format(new Date(latestReadContent.readOn), 'p')}`}
                                variant='f1'
                                color={colorTheme.darkFaded}
                                align='right'
                                className='w-full'
                              />
                            }
                          </>
                        );
                      } else {
                        // Other's message
                        return (
                          <RecipientChatMessage
                            key={chatContent.conversationContentId}
                            message={chatContent.content}
                            date={new Date(chatContent.createdOn)}
                            profile={chatContent.user}
                            media={
                              chatContent.media.length > 0
                                ? chatContent.media[0]
                                : undefined
                            }
                          />
                        );
                      }
                    })}
                  

                  <div ref={scrollToElement} />

                  {uploadedUri && (
                    <MyChatMessage
                      date={new Date()}
                      image={uploadedUri}
                      fileName={attachmentName}
                      uploading={uploadMedia.isLoading}
                    />
                  )}
                </div>
                ) : ( 
                <div style={{height: 'calc(100% - 48px)'}} className="flex justify-center items-center">
                  <BeatLoader
                    color={colorTheme.primary}
                    loading={true}
                    margin={2}
                    size={15}
                  />
              </div>) }
              
              </StyledChatScrollable>

              {isContentReady && isConnectedToHub && 
                <div className="chat-input">
                  <ChatText
                    canChat={
                      recipient.userStatus !== 'disabled' &&
                      recipient.userStatus !== 'deleted' &&
                      !recipient.isBanned &&
                      !reporter
                    }
                    fromReported={!!reporter}
                    isBanned={recipient.isBanned}
                    conversationEnded={data?.status === 'ended'}
                    onSendMessage={onSendMessage}
                    onSelectMedia={onSendMedia}
                    setUploadedUri={setUploadedUri}
                  />
                </div>
              }
            </div>
          )}
        </StyledChatTest>
      )}
    </AdminPage>
  );
};

export default AdminChatRoomPage;
