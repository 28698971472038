import React, {useCallback, useEffect, useState} from 'react';
import {colorTheme} from '../../../core/configs';
import {Icon} from '../../../core/components';
import {StyledNotificationBadge} from './styled';
import {useGetUnreadNotifications} from '../../server/react-query';
import { useQueryClient } from 'react-query';

interface NotificationBadgeProps {
  onClick: () => void;
}

const NotificationBadge = ({onClick}: NotificationBadgeProps) => {
  const [
    unreadNotificationsCount,
    setUnreadNotificationsCount,
  ] = useState<number>(0);
  const [intervalRef, setIntervalRef] = useState<NodeJS.Timeout | undefined>(undefined);

  const queryClient = useQueryClient();
  const {data} = useGetUnreadNotifications();

  useEffect(() => {
    return () => intervalRef && clearInterval(intervalRef);
  }, [intervalRef]);

  useEffect(() => {
    if (data) {
      setUnreadNotificationsCount(data);
    }
  }, [data]);

  const fetchData = useCallback(() => {
    const intervalRef = setInterval(async () => {
      await queryClient.fetchQuery('get-unread-notifications');
    }, 30_000);
    setIntervalRef(intervalRef);
  }, [queryClient]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <StyledNotificationBadge
      hasRightPadding={unreadNotificationsCount > 0}
      onClick={onClick}>
      <Icon name="bell" size={24} color={colorTheme.dark} type="button" />
      {unreadNotificationsCount > 0 && (
        <span className="badge flex justify-center">
          {unreadNotificationsCount}
        </span>
      )}
    </StyledNotificationBadge>
  );
};

export default NotificationBadge;
