import logo from '../../../assets/KAZAM_logo_Transparent.svg';
import {StyledLoader, StyledNavBarWrapper, StyledOutline} from './styled';
import {Icon, Typography} from '..';
import {colorTheme} from '../../configs';
import {IconName} from '../icon';
import React, {useState} from 'react';
import Backdrop from '../backdrop/Backdrop';
import {RoutePath} from '../../../app/navigation/config/RouteConfig';
import {useHistory, useLocation} from 'react-router-dom';
import {Action} from 'history';
import {isMobile} from 'react-device-detect';
import SideBar from '../side-drawer/SideBar';
import {useAuth} from '../../../app/hooks/useAuth';
import {useAnalytics} from '../../../app/hooks/useAnalytics';
import { navItems, secondaryNavItems, unAuthenticatedNavItems } from '../side-drawer/side-bar.types';
import { localization } from '../../../app/localization/Localization';
import NavBarMenus from './NavBarMenus';

interface NavbarProps {
  showRefresh?: boolean;
  showMenu?: boolean;
  showBackButton?: boolean;
  showRightIcon?: boolean;
  showRightText?: boolean;
  showRightComponent?: boolean;
  rightIconName?: IconName;
  rightText?: string;
  rightComponent?: React.ReactNode;
  onRefreshClick?: () => void;
  onBackClick?: () => void;
  onRightActionClick?: () => void;
  rightActionOutline?: boolean;
  rightActionIsLoading?: boolean;
}

const NavBar = (props: NavbarProps) => {
  const {authData} = useAuth();
  const {systemLogEvent} = useAnalytics();
  const location = useLocation();
  const history = useHistory();
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const backButtonClickHandler = () => {
    if (history.action === Action.Push) {
      history.go(-1); //.back() will throw an error
    } else {
      // No more previous page; replace to home
      history.replace('/');
    }
  };

  const drawerToggleClickHandler = () => {
    setSideDrawerOpen(prevState => !prevState);
    if (!authData) {
      systemLogEvent('non_user_clicked_burger_menu');
    }
  };

  const backdropClickHandler = () => {
    setSideDrawerOpen(false);
  };

  return (
    <StyledNavBarWrapper>
      <header className="navBar">
        <nav>
          {isMobile && (
            <div className="navBarItem">
              <div className="flex space-x-4">
                <div>
                  {props.showBackButton && (
                    <Icon
                      name="arrowLeft"
                      size={24}
                      color={colorTheme.dark}
                      type="button"
                      onClick={
                        props.onBackClick || backButtonClickHandler
                      }></Icon>
                  )}
                  {props.showMenu && (
                    <Icon
                      name="menu"
                      size={24}
                      color={colorTheme.dark}
                      type="button"
                      onClick={drawerToggleClickHandler}></Icon>
                  )}
                </div>

                <div>
                  {props.showRefresh && (
                    <Icon
                      name="rotateCw"
                      size={24}
                      color={colorTheme.dark}
                      type="button"
                      onClick={props.onRefreshClick}></Icon>
                  )}
                </div>
              </div>
            </div>
          )}

          <div
            className="flex logo-wrapper absolute-wrapper space-x-4"
            onClick={() => history.replace(RoutePath.LANDING)}>

            <img src={logo} alt="Logo" />

            {!isMobile && authData === null &&
              <NavBarMenus menuList={unAuthenticatedNavItems}/>
            }
          </div>

          

          <div className="navBarItem flex space-x-4">
            {props.showRefresh && !isMobile && (
              <Icon
                name="rotateCw"
                size={24}
                color={colorTheme.dark}
                type="button"
                onClick={props.onRefreshClick}></Icon>
            )}
            {props.showRightIcon && (
              <Icon
                name={props.rightIconName ?? 'messageMulti'}
                size={24}
                color={colorTheme.dark}
                type="button"
                onClick={props.onRightActionClick}></Icon>
            )}
            {props.showRightComponent && (
              <div onClick={props.onRightActionClick}>
                {props.rightComponent}
              </div>
            )}
            {props.showRightText && !props.rightActionOutline && (
              <div onClick={props.onRightActionClick}>
                {props.rightActionIsLoading ? (
                  <StyledLoader></StyledLoader>
                ) : (
                  <Typography
                    label={props.rightText ?? 'Save'}
                    variant="f2"
                    weight="normal"
                    align="center"
                    className="cursor-pointer"
                    color={colorTheme.dark}
                  />
                )}
              </div>
            )}

            {props.showRightText && props.rightActionOutline && (
              <div>
                <StyledOutline onClick={props.onRightActionClick}>
                  <Typography
                    label={props.rightText ?? 'Save'}
                    variant="f1"
                    weight="semibold"
                    align="center"
                    className="cursor-pointer"
                    color={colorTheme.dark}
                  />
                </StyledOutline>
              </div>
            )}
          </div>
        </nav>
      </header>

      {isMobile && <SideBar sideDrawerOpen={sideDrawerOpen}></SideBar>}
      {sideDrawerOpen && <Backdrop onClick={backdropClickHandler} />}
    </StyledNavBarWrapper>
  );
};

export default NavBar;
