import React, {useCallback, useEffect, useReducer, useRef, useState} from 'react';
import {useHistory, useParams, useLocation} from 'react-router-dom';
import {
  useAcceptDisclaimer,
  useGetConversationById,
  useGetConversationContent,
  usePostMarkAllMessagesAsRead,
  usePostMarkConversationContentAsRead,
} from '../../server/react-query';
import ChatProfileView from '../../views/sticky-cards/chat-profile/ChatProfileView';
import ChatText, { ChatDisabledType } from '../../views/sticky-cards/chat-text/ChatText';
import MyChatMessage from '../../views/cards/my-chat-message/MyChatMessage';
import RecipientChatMessage from '../../views/cards/recipient-chat-message/RecipientChatMessage';
import {
  ChatContentResponse,
  KazamException,
  UserResponse,
} from '../../server/types';
import Page from '../../../core/components/page/Page';
import {useAuth} from '../../hooks/useAuth';
import {RoutePath} from '../../navigation/config/RouteConfig';
import {ConversationClient} from '../../../conversation-client';
import {StyledChatScrollable, StyledChatTest, StyledSendJobOffer, StyledOfferCard,StyledChatMessageMenu} from './styled';

import {useUploadChatMedia} from '../../server/react-query';
import {useDialog} from '../../../core/components/dialog';
import DisclaimerDialog from '../../views/dialog-content/disclaimer/DisclaimerDialog';
import SendJobOfferDialog from '../../views/dialog-content/send-job-offer/SendJobOfferDialog'
import {Action} from 'history';
import ChatRoomMenu from './ChatRoomMenu';
import {useAnalytics} from '../../hooks/useAnalytics';
import {useToast} from '../../../core/components/toast';
import {useQueryClient} from 'react-query';
import {LoadMore, Typography, Icon} from '../../../core/components';
import {BeatLoader} from 'react-spinners';
import {colorTheme} from '../../../core/configs';
import AdminChatProfileView from '../../views/sticky-cards/admin-chat-profile/AdminChatProfileView';
import {
  useGetActiveSubscription,
  useGetLatestSubscription,
} from '../../server/react-query/useUserSubscription';
import SubscriptionsDialogContentView from '../../views/dialog-content/paywall/subscriptions-dialog/SubscriptionsDialogContentView';
import {useGetActiveTrialSubscription} from '../../server/react-query/useTrialSubscription';
import format from 'date-fns/format';
import {getHiredLockDuration, getPaywall} from '../../utils/admin.util';
import InAppCallNotificationView from '../call-room/InAppCallNotificationView';
import ChatJobOfferAccepted from '../../views/cards/chat-job-offer-approval/ChatJobOfferAccepted';
import ChatJobOfferDeclined from '../../views/cards/chat-job-offer-approval/ChatJobOfferDeclined';
import ChatJobOfferContractStartDate from '../../views/cards/chat-job-offer-contract-start/ChatJobOfferContractStartDate';
import _ from 'lodash';
import { JobHistoryResponse, JobOfferMeta } from '../../server/types/job-offer.types';
import addDays from 'date-fns/addDays';
import { getContentJoboffer, getContentMeta, getJobDetailsContentMeta } from '../../utils/job-offer.util';
import UpdateJobOfferDialog from '../../views/dialog-content/send-job-offer/UpdateJobOfferDialog';
import ChatMenuOptions from './ChatMenuOptions';
import ChatLocation from '../../views/cards/chat-location/ChatLocation';
import ChatJobOfferDetails from '../../views/cards/chat-job-offer-details/ChatJobOfferDetails';
import { CurrentLocationMeta } from '../../server/types/conversation.types';
import ChatJobHistoryEdited from '../../views/cards/chat-job-history-edited/ChatJobHistoryEdited';
import ChatJobEnded from '../../views/cards/chat-job-ended/ChatJobEnded';
import JobHistoryDialog from '../../views/dialog-content/job-history-modal/JobHistoryDialog';
import { TerminationReason } from '../../server/types/job-history.type';
import ChatGeneratedContract from '../../views/cards/chat-generated-contract/ChatGeneratedContract';

interface ContentFetchParams {
  page: number
}

interface ChatRoomPageParams {
  id: string;
}

const sortConversation = (
  data: ChatContentResponse[],
): ChatContentResponse[] => {
  return data.sort(
    (a: ChatContentResponse, b: ChatContentResponse) =>
      Date.parse(a.createdOn) - Date.parse(b.createdOn),
  );
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'SET_PAGE':
      return {
        ...state,
        page: action.payload.page
      }
    case 'REFETCH_LATEST':
      return {
        ...state,
        page: 1
      }
    default:
      return state
  }
}

const ChatPageTest = () => {
  const history = useHistory();
  const location = useLocation();

  const {systemLogEvent} = useAnalytics();
  const {presentDialog, dismissDialog} = useDialog();
  const {authData, updateAuthData} = useAuth();
  const {presentToast} = useToast();
  const queryClient = useQueryClient();

  const {id: channelId} = useParams<ChatRoomPageParams>();
  const params = new URLSearchParams(location.search);
  const [fromCarousel] = useState<string>(params.get('fromCarousel') || '');
  const [callType] = useState<string>(params.get('callType') || '');
  const [notifTime] = useState<string>(params.get('notifTime') || '');

  const {data, refetch} = useGetConversationById(channelId);
  const [recipient, setRecipient] = useState<UserResponse>();
  const [isHomeowner, setIsHomeowner] = useState(false);
  const [isMenuOptionsOpen, setIsMenuOptionsOpen] = useState(false);
  const [conversationClient, setConversationClient] = useState<
    ConversationClient | undefined
  >(undefined);
  const [conversationContents, setConversationContents] = useState<
    ChatContentResponse[] | undefined
  >(undefined);
  const [userId, setUserId] = useState<string | undefined>(
    authData?.user.userId,
  );
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isConnectedToHub, setIsConnectedToHub] = useState<boolean>(false);
  const [isJoined, setIsJoined] = useState<boolean>(false);
  const [uploadedUri, setUploadedUri] = useState<string | undefined>('');
  const [attachmentName, setAttachmentName] = useState<string | undefined>('');
  const [newReceivedMessage, setNewReceivedMessage] = useState<
    ChatContentResponse | undefined
  >(undefined);
  const [isAllMessagesFetched, setIsAllMessagesFetched] = useState<boolean>(
    false,
  );
  const [isScrollable, setIsScrollable] = useState<boolean>(true);
  const [latestReadContent, setLatestReadContent] = useState<
    ChatContentResponse | undefined
  >(undefined);

  const [isAddAttachmentTriggered , setIsAddAttachmentTriggered] = useState<boolean>(false);


  const [scrollTopValue, setScrollTopValue] = useState<number>(0);
  const [isContentReady, setIsContentReady] = useState<boolean>(false);
  const [chatSessionId] = useState<string>(new Date().getTime().toString());
  const [chatDisabledType, setChatDisabledType] = useState<ChatDisabledType | null>(null);
  const [latestJobStartDate, setLatestJobStartDate] = useState<Date | null>(null);
  const [chatResumeDate, setChatResumeDate] = useState<Date | null>(null);
  const [homeownerHasActiveOffer, setHomeownerHasActiveOffer] = useState<boolean>(false);

  const {
    data: userSubscription,
    isFetching: fetchingSubscription
  } = useGetActiveSubscription(true);
  const {data: latestSubscription} = useGetLatestSubscription();
  const {data: userTrialSubscription} = useGetActiveTrialSubscription();

  const INITIAL_PARAMS: ContentFetchParams = {
    page: 1
  }
  const [contentParams, dispatchSearch] = useReducer(reducer, INITIAL_PARAMS)

  const {
    data: hookDataConversationContent,
    refetch: refetchConversations,
    isFetching,
  } = useGetConversationContent(channelId, contentParams.page);

  useEffect(() => {
    if (!isAllMessagesFetched) {
      (async () => {
        await refetchConversations();
      })();
    } else {
      console.log('All messages have been fetched');
    }
  }, [pageNumber]);

  useEffect(() => {
    if (conversationContents && contentParams?.page === 1) {
      scrollToBottom();
    }
  }, [conversationContents]);

  useEffect(() => {
    if (!!callType
      && !!notifTime) {
      const notificationTime = new Date(`${notifTime} UTC`)
      const expirationTime = new Date(notificationTime.getTime() + 30 * 1000)
      const localCurrentTime = new Date().toLocaleString()
        
      if (
        new Date(localCurrentTime).getTime() < expirationTime.getTime()
      ) {
        showCallDialog(callType === 'video', "")
      }
    }
  }, [callType, notifTime]);

  const postMarkAllMessagesAsRead = usePostMarkAllMessagesAsRead(channelId);

  const postMarkConversationContentAsRead = usePostMarkConversationContentAsRead(
    {
      conversationContentIds: [newReceivedMessage?.conversationContentId || ''],
    },
  );

  const listenToEvents = useCallback(() => {
    conversationClient!.on('connecting', () => {
      // console.log('connecting');
      setIsJoined(false);
      setIsConnectedToHub(false);
    });

    conversationClient!.on('connected', (connectionId: string | null) => {
      // console.log('connected', {connectionId});
      setIsConnectedToHub(true);
      scrollToBottom();
    });

    conversationClient!.on('disconnected', (error: Error | undefined) => {
      console.log('disconnected', {error});
      setConversationClient(undefined);
      setIsJoined(false);
      setIsConnectedToHub(false);
    });

    conversationClient!.on('messageReceive', async (data: string) => {
      systemLogEvent('chat_message_received', {session_id: chatSessionId});
      let content: ChatContentResponse;
      try {
        content = JSON.parse(data) as ChatContentResponse;

        if (content.content.includes('KAZAM_SYSTEM')
          && (!isHomeowner || content.user.userRole === 'admin')){
          showCallDialog(
            content.content === 'KAZAM_SYSTEM_VIDEO_CALL',
            content.conversationContentId
          )
        }
        
      } catch (e) {
        console.log(e);
        return;
      }

      // Check for attachments
      if (content.media && content.media.length > 0) {
        systemLogEvent('chat_message_with_attachment_received', {
          session_id: chatSessionId,
        });
      }

      setConversationContents(prevState =>
        sortConversation([...(prevState || []), content]),
      );
      setNewReceivedMessage(content);
      scrollToBottom();

      // Invalidate cache
      queryClient.removeQueries([`get-conversation-content`, channelId]);
      queryClient.invalidateQueries([`get-conversation-by-id`, channelId]);
    });

    conversationClient!.on('conversationEnded', async (data: string) => {
      refetch();
    });

    conversationClient!.on('conversationEnabled', async (data: string) => {
      refetch();
    });

    conversationClient!.on('reFetchContents', async (data: string) => {
      console.log("reFetchContents");
      refetchLatestConversationContents()
      queryClient.invalidateQueries([`get-conversation-by-id`, channelId]);
    });
  }, [conversationClient]);

  useEffect(() => {
    // Every time the user opens the chat room page is consider a new session
    systemLogEvent('unique_chat_session', {session_id: chatSessionId});

    (async () => {
      await postMarkAllMessagesAsRead.mutateAsync();
    })();

    return () => {
      // setConversationContents(undefined);
    };
  }, []);

  useEffect(() => {
    if (newReceivedMessage && newReceivedMessage.userId !== userId) {
      // mark message as read
      (async () => {
        await postMarkConversationContentAsRead.mutateAsync();
        conversationClient?.triggerRefetchContents();
        // console.log('Message marked as read');
      })();
    }
  }, [userId, newReceivedMessage]);

  useEffect(() => {
    if (conversationClient && isConnectedToHub && userId) {
      // Join conversation
      (async () => {
        const joinSuccessful = await conversationClient.join(userId);
        setIsJoined(joinSuccessful);
        
        var justSentLocation = localStorage.getItem('sentCurrentLocation')
        if (!!justSentLocation){
          conversationClient.triggerRefetchContents()
          localStorage.removeItem('sentCurrentLocation')
        }

        conversationClient?.triggerRefetchContents()
      })();
    }
  }, [conversationClient, isConnectedToHub, userId]);

  useEffect(() => {
    let timeoutRef: NodeJS.Timeout;
    if (isContentReady) {
      timeoutRef = setTimeout(() => {
        scrollToBottom();
      }, 200);
    }
    return () => {
      timeoutRef && clearTimeout(timeoutRef);
    };
  }, [isContentReady]);

  useEffect(() => {
    if (hookDataConversationContent) {
      setIsContentReady(true);
      if (hookDataConversationContent.length > 0) {
        let newContents: ChatContentResponse[] = [];
        let oldData = [...conversationContents ?? []]
        if (conversationContents) {
          for (let newData of hookDataConversationContent) {
            const isFound = !!conversationContents.find(
              oldData =>
                oldData.conversationContentId === newData.conversationContentId,
            );

            if (!isFound) {
              newContents.push(newData);
            } else {
              if (!!newData.meta
                || !!newData.readOn
              ) {
                let index = oldData.findIndex(c => c.conversationContentId == newData.conversationContentId)
                oldData[index].meta = newData.meta
                oldData[index].readOn = newData.readOn
              }
            }
          }
        } else {
          newContents = hookDataConversationContent;
        }

        if (hookDataConversationContent.length === 10) {
          setIsAllMessagesFetched(false);
        } else {
          setIsAllMessagesFetched(true);
        }
        
        var latestContents = sortConversation([oldData || [], newContents].flat())
        setConversationContents(latestContents);
        setNewReceivedMessage(latestContents[latestContents.length - 1])
        setLatestReadContentResponse(latestContents)

        // if (pageNumber === 1) {
        //   setConversationContents(prevState => {
        //     return sortConversation(hookDataConversationContent);
        //   });
        // } else {
        //   setConversationContents(prevState => {
        //     return sortConversation([prevState || [], newContents].flat());
        //   });
        // }
        
      } else {
        setIsAllMessagesFetched(true);
      }
    }
  }, [hookDataConversationContent]);

  useEffect(() => {
    if (conversationClient) {
      listenToEvents();
      (async () => {
        // Connect to hub
        await conversationClient.create(channelId);
      })();
    }
  }, [conversationClient, channelId, listenToEvents]);

  useEffect(() => {
    if (!conversationClient) {
      const baseUrl = process.env.REACT_APP_BASE_URL;
      // const baseUrl = "http://localhost:5000/";
      const convoClient = new ConversationClient({
        url: `${baseUrl}conversation-hub`,
        logLevel: 'none',
      });
      setConversationClient(convoClient);
    }
    return () => {
      if (conversationClient) {
        (async () => {
          await conversationClient.stop();
        })();
      }
    };
  }, [conversationClient]);

  useEffect(() => {
    if (data) {
      const recipient = data.members.find(m => m.userId !== userId);
      setRecipient(recipient?.user);
    }
  }, [data, userId]);

  useEffect(() => {
    if (authData) {
      setIsHomeowner(authData.user.userRole === 'homeowner');
      setUserId(authData.user.userId);

      if (!authData.user.lastAcceptedChatDisclaimer) {
        showDisclaimerDialog();
      }
    }
  }, [authData]);


  useEffect(() => {
    if (recipient) {
      setChatDisabledType(getDisabledChatType())
    }
  }, [recipient]);
  

  const showCallDialog = (
    video: boolean,
    contentId: string) => {
    presentDialog({
      headerText: '',
      enableBackdropDismiss: false,
      hideClose: true,
      fullWidth: true,
      hideHeader: true,
      useFullScreen: true,
      content: (
        <InAppCallNotificationView
          id={channelId}
          contentId={contentId}
          callType={video ?'video' : 'voice'}
        />
      ),
    });
  }

  const showSendOfferDialog = () => {
    if (recipient) {
      presentDialog({
        headerText: '',
        enableBackdropDismiss: false,
        content: (
          <SendJobOfferDialog
            channelId={channelId}
            recipientId={recipient.userId}
            recipientSalary={recipient?.filterSetting?.salary ?? 0}
            onCancel={dismissDialog}
            onCreated={() => {
              queryClient.invalidateQueries([`get-conversation-by-id`, channelId]);
              refetchLatestConversationContents()
              dismissDialog()
              conversationClient?.triggerRefetchContents();
            }}
            onError={v => showError(v)}
          />
        ),
      });
    }
  } 

  const showUpdateOfferDialog = (jobOfferId: string, contentId: string) => {
    if (recipient) {
      presentDialog({
        headerText: '',
        enableBackdropDismiss: false,
        content: (
          <UpdateJobOfferDialog
            jobOfferId={jobOfferId}
            channelId={channelId}
            contentId={contentId}
            recipientId={recipient.userId}
            onCancel={dismissDialog}
            onUpdated={() => {
              refetchLatestConversationContents()
              dismissDialog()
              conversationClient?.triggerRefetchContents();
            }}
            onError={v => showError(v)}
          />
        ),
      });
    }
  }

  const showJobHistoryDialog = (
    data: JobHistoryResponse,
    meta: JobOfferMeta,
    role: string,
    contentId?: string,
    confirmRating?: boolean
  ) => {
    presentDialog({
      headerText: '',
      enableBackdropDismiss: false,
      content: (
        <JobHistoryDialog 
          data={data}
          meta={meta}
          jobHistoryId={data.jobHistoryId ?? ""}
          contentId={contentId ?? ''}
          roles={role ?? ''}
          disable
          confirmRating
          onError={v => showError(v)}
          onUpdated={() => {
            refetchLatestConversationContents()
            dismissDialog()
            conversationClient?.triggerRefetchContents();
          }}
        />
      ),
    });
  } 

  const showDisclaimerDialog = () => {
    presentDialog({
      headerText: '',
      content: (
        <DisclaimerDialog
          onCancel={cancelDisclaimer}
          onContinue={acceptDisclaimer}
        />
      ),
      enableBackdropDismiss: false,
      hideClose: true,
    });
  };

  const cancelDisclaimer = () => {
    dismissDialog();
    if (history.action === Action.Push) {
      history.go(-1); //.back() will throw an error
    } else {
      // No more previous page; replace to home
      history.replace('/');
    }
  };

  const acceptDisclaimerMutation = useAcceptDisclaimer();
  const acceptDisclaimer = async () => {
    var user = await acceptDisclaimerMutation.mutateAsync();
    if (user) {
      let data = authData;
      if (data) {
        data.user = user;
        updateAuthData(data);
      }
    }
    dismissDialog();
  };

  const profileClickedHandler = (user: UserResponse) => {
    if (isHomeowner) {
      history.push(
        RoutePath.JOB_SEEKER_PROFILE_VIEW.replace(':id', user.userId),
      );
    } else {
      history.push(RoutePath.EMPLOYER_PROFILE_VIEW.replace(':id', user.userId));
    }
  };

  const callHandler = (callType: string) => {
    onSendMessage(`KAZAM_SYSTEM_${callType.toUpperCase()}_CALL`);
  };

  const onSendMessage = async (message: string) => {
    if (conversationClient && userId && isJoined) {
      await conversationClient.send(userId, message);
    }
  };

  const uploadMedia = useUploadChatMedia();
  const onSendMedia = async (file: any | null) => {
    try {
      if (!file.type.includes('image')) {
        setUploadedUri(undefined);
        setAttachmentName(file.name);
      } else {
        setAttachmentName(undefined);
      }

      var content = await uploadMedia.mutateAsync({
        file: file,
        channelId: channelId,
      });
      if (content && conversationClient && userId) {
        setUploadedUri(undefined);
        await conversationClient.sendMedia(userId, content);
      }
    } catch (e: any) {
      setUploadedUri(undefined);
      setAttachmentName(undefined);
      showError(e.data.error);
    }
  };

  const chatContainer = useRef<HTMLDivElement>(null);
  const scrollToElement = useRef<HTMLDivElement>(null);
 
  useEffect(() => {
    if (contentParams?.page === 1) scrollToBottom()
  }, [chatContainer.current?.scrollHeight])

  useEffect(() => {
    dispatchSearch({
      type: 'SET_PAGE',
      payload: {
        page: pageNumber
      },
    });
  }, [pageNumber])

  const scrollToBottom = () => {
    if (scrollToElement.current) {
      scrollToElement.current.scrollIntoView({behavior: 'smooth'});
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLElement>): void => {
    e.stopPropagation(); // Handy if you want to prevent event bubbling to scrollable parent
    setScrollTopValue(e.currentTarget.scrollTop);
  };

  const onLoadPrevMessages = () => {
    setPageNumber(prevState => prevState + 1);
  };

  const userCanChat = (): boolean => {
    if (!!recipient && recipient.userRole === 'admin') return true;

    return (
      !!recipient &&
      !recipient.isBanned &&
      !['disabled', 'deleted'].includes(recipient.userStatus) &&
      authData?.user?.userStatus !== 'suspended'
    );
  };

  const getDisabledChatType = (): ChatDisabledType | null => {
    if (recipient) {
      if (authData?.user?.userStatus === 'suspended') return 'senderIsSuspended'

      switch (recipient?.userRole) {
        case 'admin':
          return data?.status === 'ended' ?"conversationWithAdminEnded" : null
        case 'kazam':
          if (checkIfKasambahayIsHired(recipient?.jobHistory ?? [])) return 'recipientIsHired'
          checkIfRecipientStatus()
          return null
        case 'homeowner':
          const currentUser = data?.members.find(m => m.userId !== recipient.userId);
          if (checkIfKasambahayIsHired(currentUser?.user?.jobHistory ?? [])) return 'senderIsHired'
          checkIfRecipientStatus()
          return null
        default:
          return null
      }
    }
    
    return null
  };

  const checkIfRecipientStatus = (): ChatDisabledType | null => {
    if (recipient) {
      if (recipient?.isBanned) return 'recipientIsBanned'
      if (['disabled', 'deleted'].includes(recipient.userStatus)) return 'recipientIsDisabled'
    }
    return null
  };

  const checkIfKasambahayIsHired = (
    jobHistory: JobHistoryResponse[]
  ): boolean => {
    const lockDuration = getHiredLockDuration();

    if (jobHistory.length > 0) {
      const sortedJH = _.sortBy(jobHistory, (item) => {
        return new Date(item.createdOn);
      }).reverse()
        
      const latestJH = sortedJH[0]
      const isActiveOrPending = ['active', 'pending'].includes(latestJH.status)
      const dateToday = new Date()
      const lockedEndDate = addDays(new Date(latestJH.startDate), lockDuration)
      const canChat = addDays(lockedEndDate, 1)
      setChatResumeDate(canChat)
      setLatestJobStartDate(new Date(latestJH.startDate))
      
      const isHiredLock = dateToday < lockedEndDate
      const hiredByMe = latestJH.employerId === authData?.user?.userId
      const hiredInChat = latestJH.employeeId === authData?.user?.userId
        && latestJH.employerId === recipient?.userId
        
      return isActiveOrPending
        && isHiredLock
        && ((!hiredByMe && isHomeowner)
        || (!hiredInChat && !isHomeowner))
    }

    return false
  }

  const showPaywallDialog = () => {
    presentDialog({
      headerText: '',
      enableBackdropDismiss: true,
      content: (
        <SubscriptionsDialogContentView
          userId={recipient?.userId}
          onSuccess={() => dismissDialog()}
          onError={v => showError(v)}
        />
      ),
    });
  };

  const userIsSubscribed = () => {
    if (authData?.user?.userRole !== 'homeowner') return true;
    if (recipient?.userRole === 'admin') return true;

    const paywall = getPaywall();
    if (!paywall || (!!paywall && !paywall.enabled)) return true;

    return (
      !!userSubscription ||
      !!userTrialSubscription ||
      authData?.user?.isEligibleForTrial
    );
  };

  const getUnsubscribedMessage = (): string | null => {
    let message = null;

    if (data?.hiddenMessages && data.hiddenMessages > 0)
      return `You have ${data.hiddenMessages} unread 
      ${data.hiddenMessages > 1 ? 'messages' : 'message'} from 
      ${
        recipient?.firstName
      }. To view messages, click link below to subscribe.`;

    if (!userSubscription && latestSubscription) {
      return `Your latest subscription expired on 
        ${format(new Date(latestSubscription.expiresOn), 'M/dd/yyyy')}, 
        ${format(new Date(latestSubscription.expiresOn), 'p')}. 
        To send messages, click link below to subscribe again.`;
    }

    return message;
  };

  const showError = (message: string) => {
    presentToast({
      message: message,
      variant: 'error',
      position: 'bottom',
    });
  };

  const scrollToLatest = () => {
    setTimeout(() => {
      if (contentParams?.page === 1) scrollToBottom()
    }, 200)
  }

  useEffect(() => {
    refetchConversations()
  }, [contentParams]);

  const refetchLatestConversationContents = () => {
    dispatchSearch({
      type: 'REFETCH_LATEST'
    });
  }

  const getChatBubble = (c: ChatContentResponse) => {
    switch (c.type) {
      case 'jobOffer':
        if (c.meta) {
          return (
            <ChatJobOfferDetails
              contentId={c.conversationContentId}
              currentUser={authData?.user}
              fromUser={c.user}
              offer={getContentJoboffer(c.meta)}
              date={new Date(c.createdOn)}
              onUpdate={(id) => showUpdateOfferDialog(id, c.conversationContentId)}
              onUpdateStatus={(id) => {
                // queryClient.invalidateQueries([`get-job-offer`, id])
                queryClient.invalidateQueries([`get-conversation-by-id`, channelId]);
                refetchLatestConversationContents()
                conversationClient?.triggerRefetchContents();
              }}
              onDataLoaded={scrollToLatest}
            />
          )
        }
        break;
      case 'acceptedJobOffer':
        if (c.meta) {
          return (
            <ChatJobOfferAccepted
              sender={authData?.user}
              recipient={recipient}
              meta={getContentMeta(c.meta)}
              offer={getContentJoboffer(c.meta)}
              date={new Date(c.createdOn)}
            />
          )
        }
        break;
      case 'generatedContract':
        if (c.meta) {
          console.log(getContentJoboffer(c.meta))
          return (
            <ChatGeneratedContract
              sender={authData?.user}
              recipient={recipient}
              meta={getContentMeta(c.meta)}
              offer={getContentJoboffer(c.meta)}
              date={new Date(c.createdOn)}
            />
          )
        }
        break;
      case 'declinedJobOffer':
        if (c.meta) {
          return (
            <ChatJobOfferDeclined
              sender={authData?.user}
              recipient={recipient}
              meta={getContentMeta(c.meta)}
              offer={getContentJoboffer(c.meta)}
              date={new Date(c.createdOn)}
            />
          )
        }
        break;
      case 'jobOfferStartDate':
        if (c.meta) {
          return (
            <ChatJobOfferContractStartDate
              contentId={c.conversationContentId}
              sender={authData?.user}
              recipient={recipient}
              meta={getContentJoboffer(c.meta)}
              date={new Date(c.createdOn)}
              onStarted={(id) => {
                queryClient.invalidateQueries([`get-job-offer`, id])
                queryClient.invalidateQueries([`get-conversation-by-id`, channelId]);
                refetchLatestConversationContents()
                conversationClient?.triggerRefetchContents();
              }}
              onDataLoaded={scrollToLatest}
            />
          )
        }
        break;
      case 'cancelledJobOffer':
        if (c.meta) {
          return (
            <ChatJobOfferDetails
              contentId={c.conversationContentId}
              currentUser={authData?.user}
              fromUser={c.user}
              offer={getContentJoboffer(c.meta)}
              date={new Date(c.createdOn)}
            />
          )
        }
        break;
      case 'currentLocation':
        if (c.meta) {
          const meta = JSON.parse(c.meta) as CurrentLocationMeta
          if (meta) {
            return (
              <ChatLocation
                currentUser={authData?.user}
                fromUser={c.user}
                contentId={c.conversationContentId}
                lat={meta.lat}
                long={meta.long}
                address={meta.text}
                date={new Date(c.createdOn)}
                // onDataLoaded={scrollToLatest}
              />
            )
          }
        }
        break;
      case 'jobHistoryEdited':
        if (c.meta) {
          const meta = getJobDetailsContentMeta(c.meta)
          return (
            <ChatJobHistoryEdited
              sender={c.user.firstName}
              date={new Date(c.createdOn)}
              updatedFields={meta.updatedFields}
            />
          )
        }
        break;
      case 'jobEnded':
        if (c.meta) {
          return (
            <ChatJobEnded
              user={authData?.user}
              meta={getJobDetailsContentMeta(c.meta)}
              date={new Date(c.createdOn)}
              onUpdate={(jh, om, r) => showJobHistoryDialog(jh, om, r, c.conversationContentId, true)}
            />
          )
        }
        break;
      default:
        if (c.userId === userId) {
          return (
            <MyChatMessage
              key={c.conversationContentId}
              message={c.content}
              meta={c?.meta}
              date={new Date(c.createdOn)}
              media={
                c.media.length > 0
                  ? c.media[0]
                  : undefined
              }
              isHomeowner={isHomeowner}
              conversationId={channelId}
              isSubscribed={userIsSubscribed()}
              onCallClick={(v) => callHandler(v ? 'video' : 'voice')}
            />
          );
        } else {
          // Other's message
          if (!c.isHidden) {
            return (
              <RecipientChatMessage
                key={c.conversationContentId}
                message={c.content}
                meta={c?.meta}
                date={new Date(c.createdOn)}
                profile={c.user}
                conversationId={channelId}
                media={
                  c.media.length > 0
                    ? c.media[0]
                    : undefined
                }
              />
            );
          }
        }
    }
  };
  const handleMenuOptions = () => {
    setIsMenuOptionsOpen(!isMenuOptionsOpen);
  }

  const setLatestReadContentResponse = (data: ChatContentResponse[]) => {
    const hasReadOn = data?.some(item => item?.readOn);
    if(hasReadOn){
      const mySentMessages = data?.filter(c => c.user.userId === authData?.user.userId
        && !!c.readOn
      )
      
      if (mySentMessages.length > 0) {
        const latestRead = mySentMessages[mySentMessages.length - 1]
        if (latestRead) setLatestReadContent(latestRead)
      }
    }
  };

  return (
    <Page
      showBackButton
      showRightComponent={recipient && recipient.userStatus === 'active'}
      rightComponent={
        recipient &&
        recipient.userRole !== 'admin' && (
          <ChatRoomMenu channelId={channelId} myUserId={userId || ''} />
        )
      }
      unPadded
      showLoading={fetchingSubscription}>
      <StyledChatTest notSubscribed={!userIsSubscribed()}>
        <div className="header">
          <div className="profile">
            {recipient &&
              (recipient.userRole === 'admin' ? (
                <AdminChatProfileView profile={recipient} />
              ) : (
                <ChatProfileView
                  profile={recipient}
                  fromCarousel={!!fromCarousel}
                  conversationId={channelId}
                  isSubscribed={userIsSubscribed()}
                  chatDisabledType={chatDisabledType}
                  onClick={profileClickedHandler}
                  onCallClick={callHandler}
                />
              ))}
          </div>

          <div className="profile"></div>
        </div>
        {recipient && (
          <div className="content chat-content-wrapper">
            <StyledChatScrollable
              className="chats space-y-4"
              ref={chatContainer}
              onScroll={handleScroll}
              isNotScrollable={!isScrollable}>
              {!isAllMessagesFetched && isConnectedToHub && (
                <div className="mt-4">
                  <LoadMore
                    isFetching={isFetching}
                    onClick={onLoadPrevMessages}
                  />
                </div>
              )}

              {isContentReady 
              && isConnectedToHub
              && !postMarkAllMessagesAsRead.isLoading ? (
                <div className="p-6 space-y-2">
                  {(conversationContents || []).map(chatContent => {
                    return (
                      <>
                        {getChatBubble(chatContent)}
                        {latestReadContent
                          && latestReadContent.readOn
                          && latestReadContent.conversationContentId === chatContent.conversationContentId &&
                          <Typography 
                            label={`Seen ${format(new Date(latestReadContent.readOn), 'PP')} ${format(new Date(latestReadContent.readOn), 'p')}`}
                            variant='f1'
                            color={colorTheme.darkFaded}
                            align='right'
                            className='w-full'
                          />
                        }
                      </>
                    )
                  })}

                  <div ref={scrollToElement} />

                  {uploadedUri && (
                    <MyChatMessage
                      date={new Date()}
                      image={uploadedUri}
                      fileName={attachmentName}
                      uploading={uploadMedia.isLoading}
                    />
                  )}
                </div>
              ) : (
                <div
                  style={{height: 'calc(100% - 48px)'}}
                  className="flex justify-center items-center">
                  <BeatLoader
                    color={colorTheme.primary}
                    loading={true}
                    margin={2}
                    size={15}
                  />
                </div>
              )}
            </StyledChatScrollable>

            {isHomeowner
              && recipient?.userRole === 'kazam' 
              && !chatDisabledType
              && !homeownerHasActiveOffer
              && !data?.hasActiveJobOffer
              && userIsSubscribed() &&
              <StyledSendJobOffer onClick={showSendOfferDialog}>
                <div className='flex round-layout'>
                  <Icon
                    name="themeDocumentChecked"
                    size={32}
                    color={colorTheme.dark}
                    type="button"
                  />
                  <Typography
                    className='btn-txt'
                    label='SEND JOB OFFER'
                    variant="f1"
                    weight="normal"
                    color={colorTheme.tint}
                  />
                </div>
              </StyledSendJobOffer>
            }

            <div className="chat-input">

              {isMenuOptionsOpen && (
                <ChatMenuOptions 
                  channelId={channelId}
                  hideSendOffer={data?.hasActiveJobOffer}
                  onClickAddAttachment={()=>setIsAddAttachmentTriggered(!isAddAttachmentTriggered)} 
                  onClickShowSendOfferDialog = {showSendOfferDialog}
                  onClickOpenOptions={handleMenuOptions}
                />
              )}

              <ChatText
                disabledType={chatDisabledType}
                isMenuOptionsOpen={isMenuOptionsOpen}
                isAddAttachmentTriggered = {isAddAttachmentTriggered}
                jobStartDate={latestJobStartDate}
                resumeChatDate={chatResumeDate}
                recipientName={recipient?.firstName}
                onSendMessage={onSendMessage}
                onSelectMedia={onSendMedia}
                setUploadedUri={setUploadedUri}
                isSubscribed={userIsSubscribed()}
                unSubscribedMessage={getUnsubscribedMessage()}
                onClickSubscribe={showPaywallDialog}
                onClickOpenOptions={handleMenuOptions}
              />
            </div>
          </div>
        )}
      </StyledChatTest>
    </Page>
  );
};

export default ChatPageTest;
