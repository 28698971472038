import step1 from '../../../../assets/how-the-app-works-assets/create-profile.png'
import step4 from '../../../../assets/how-the-app-works-assets/browse-candidate.png'
import step2 from '../../../../assets/how-the-app-works-assets/get-verified.png'
import step3 from '../../../../assets/how-the-app-works-assets/personality-matching.png'
import step5 from '../../../../assets/how-the-app-works-assets/chat.png'
import step6 from '../../../../assets/how-the-app-works-assets/video.png'
import step7 from '../../../../assets/how-the-app-works-assets/job-offer.png'

import { isMobile } from 'react-device-detect';
import '../style.css';
import NextArrowIcon from '../../../../assets/how-the-app-works-assets/NextArrow'
import PreviousArrowIcon from '../../../../assets/how-the-app-works-assets/PreviousArrow'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { StyledHeader, StyledHowAppWorksWrapper, StyledImgDesc } from './styled'

const HowTheAppWorks = () => {
  const items = [
    { 
      step: 'Create a Profile',
      image: step1
    },
    {
      step: 'Get Verified ID',
      image: step2
    },
    { 
      step: 'Personality Matching',
      image: step3
    },
    {
      step: 'Browse Kasambahays',
      image: step4
    },
    {
      step: 'Send a Message',
      image: step5
    },
    {
      step: 'In Chat Video Call',
      image: step6
    },
    {
      step: 'Send Job Offer',
      image: step7
    },
  ];

  return (
    <StyledHowAppWorksWrapper >
      <StyledHeader> - How The App Works - </StyledHeader>
      <Carousel
        className='w-full h-full flex flex-col items-center justify-center'
        showThumbs={false} 
        showStatus={false} 
        showArrows
        dynamicHeight={false} 
        axis='horizontal'
        emulateTouch
        renderArrowPrev={(clickHandler, hasPrev) => {
          return (
            <div
              className={`${
                hasPrev ? "absolute" : "hidden"
              } top-0 bottom-0 left-0 flex justify-center items-center z-20`}
              onClick={clickHandler}
            >
              <PreviousArrowIcon 
                width={isMobile ? '24' : '48'} 
                height={isMobile ? '24' : '48'} 
              />
            </div>
          );
        }}
        renderArrowNext={(clickHandler, hasNext) => {
          return (
            <div
              className={`${
                hasNext ? "absolute" : "hidden"
              } top-0 bottom-0 right-0 flex justify-center items-center z-20`}
              onClick={clickHandler}
            >
              <NextArrowIcon 
                width={isMobile ? '24' : '48'} 
                height={isMobile ? '24' : '48'} 
              />
            </div>
          );
        }}>
          {items.map((item, index) => (
            <div key={index} className='relative w-full h-full flex flex-col gap-12 justify-center items-center'>
              <img src={item.image} />
              <StyledImgDesc>{item.step}</StyledImgDesc>
            </div>
          ))}
      </Carousel>
    </StyledHowAppWorksWrapper>
  );
};

export default HowTheAppWorks;
