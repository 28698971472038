import kazamLogo from '../../../../assets/KAZAM_logo_Transparent.svg';
import Slider from 'react-slick';
import {Button, Icon, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {isMobile} from 'react-device-detect';
import {
  StyledBlogSectionWrapper,
  StyledBlogWrapper,
  StyledHeader,
  StyledSubHeader,
} from './styled';
import {useGetAdminControl} from '../../../server/react-query';
import {BlogDataContent} from '../../../server/types/admin-control.types';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Blogs = () => {
  const history = useHistory();

  const [blogs, setBlogs] = useState<BlogDataContent[]>();

  const {data, isFetched} = useGetAdminControl('blogData');

  useEffect(() => {
    if (data && isFetched) {
      setBlogs(JSON.parse(data?.content));
    }
  }, [data]);

  const goToBlog = (url: string) => {
    window.open(url, '_blank');
  };

  const goToNews = () => {
    window.open('https://news.kazam.ph/', '_blank');
  };

  const SimpleSlider = () => {
    return (
      <StyledBlogSectionWrapper className="flex flex-col justify-center items-center w-full">
        <div>
          <StyledHeader>- Kwentong kazam -</StyledHeader>
          <StyledSubHeader>
            for the latest news and blog posts about kazam
          </StyledSubHeader>
        </div>

        <Carousel
          showArrows={false}
          showStatus={false}
          axis="horizontal"
          className="w-full h-full flex flex-col items-center justify-center">
          {blogs?.map((blog, index) => (
            <div className="px-6">
              <StyledBlogWrapper key={index}>
                <img
                  src={blog.imageUrl}
                  alt={blog.title}
                  style={{height: '170px', width: '100%'}}
                />
                <Typography
                  label={blog.description}
                  variant="f1"
                  weight="semibold"
                  color={colorTheme.dark}
                />
                <div>
                  <Icon
                    type="button"
                    name="arrowRight"
                    size={24}
                    color={colorTheme.primary}
                    onClick={() => goToBlog(blog.url)}
                  />
                </div>
              </StyledBlogWrapper>
            </div>
          ))}
        </Carousel>
        <div>
          <Button label="Read more" color="primary" onClick={goToNews} />
        </div>
      </StyledBlogSectionWrapper>
    );
  };

  return (
    <>
      {!isMobile && (
        <StyledBlogSectionWrapper className="flex flex-col justify-center items-center w-full">
          <div>
            <StyledHeader>- Kwentong kazam -</StyledHeader>
            <StyledSubHeader>
              for the latest news and blog posts about kazam
            </StyledSubHeader>
          </div>

          <div className="flex justify-center gap-10 w-full">
            {blogs?.map((blog, index) => (
              <StyledBlogWrapper key={index}>
                <img
                  src={blog.imageUrl}
                  alt={blog.title}
                  style={{height: '170px', width: '100%'}}
                />
                <Typography
                  label={blog.description}
                  variant="f2"
                  weight="semibold"
                  color={colorTheme.dark}
                />
                <div>
                  <Icon
                    type="button"
                    name="arrowRight"
                    size={24}
                    color={colorTheme.primary}
                    onClick={() => goToBlog(blog.url)}
                  />
                </div>
              </StyledBlogWrapper>
            ))}
          </div>

          <div>
            <Button label="Read more" color="primary" onClick={goToNews} />
          </div>
        </StyledBlogSectionWrapper>
      )}

      {isMobile && <SimpleSlider />}
    </>
  );
};

export default Blogs;
