import React, {useEffect, useReducer, useState} from 'react';
import {
  useGetBannedUsers,
  useGetSystemStatistics,
  usePostUnbanUser,
} from '../../../../server/react-query';
import AdminTable from '../AdminTable';
import {StyledAdminUsersTable} from '../styled';
import {useToast} from '../../../../../core/components/toast';
import {Column} from 'react-table';
import {Typography} from '../../../../../core/components';
import {colorTheme} from '../../../../../core/configs';
import {useAnalytics} from '../../../../hooks/useAnalytics';
import {useAuth} from '../../../../hooks/useAuth';
import { reducer, INITIAL_SEARCH_STATE } from '../verified/AdminVerifiedUsersTable';

interface TableData {
  userId: string;
  name: string;
  location: string;
  numberOfReports: number;
  userType: string;
}

interface AdminBannedUsersTableProps {
  searchQuery: string;
}

const AdminBannedUsersTable = (props: AdminBannedUsersTableProps) => {
  const {searchQuery: propSearchQuery} = props;

  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [data, setData] = useState<TableData[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>(propSearchQuery);
  const [searchBody, dispatchSearch] = useReducer(reducer, INITIAL_SEARCH_STATE)
  const {
    data: hookDataBannedUsers,
    refetch: refetchGetBannedUsers,
  } = useGetBannedUsers(searchBody);
  const {
    data: hookDataSystemStatistics,
    refetch: refetchSystemStatistics,
  } = useGetSystemStatistics();
  const {systemLogEvent} = useAnalytics();
  const {authData} = useAuth();

  const {presentToast} = useToast();

  const postUnbanUser = usePostUnbanUser();

  const [columns] = useState<Column<TableData>[]>([
    {
      Header: 'Name',
      accessor: 'name', // accessor is the "key" in the data
    },
    {
      Header: 'User ID',
      accessor: 'userId',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'User Type',
      accessor: 'userType',
    },
    {
      Header: 'Action',
      accessor: 'action',
    },
  ] as Column<TableData>[]);

  useEffect(() => {
    refetchGetBannedUsers()
  }, [searchBody]);

  useEffect(() => {
    setPageNumber(1)
    setSearchQuery(propSearchQuery);
  }, [propSearchQuery]);

  useEffect(() => {
    dispatchSearch({
      type: 'SET_SEARCH',
      payload: {
        search: searchQuery?.trim(),
      },
    });
  }, [searchQuery]);

  const onPageChangedHandler = (pageNumber: number) => {
    setPageNumber(pageNumber);
    dispatchSearch({
      type: 'SET_PAGE',
      payload: {
        page: pageNumber
      },
    });
  };

  useEffect(() => {
    if (hookDataBannedUsers) {
      setPageCount(
        Math.ceil(hookDataBannedUsers.total / hookDataBannedUsers.pageSize),
      );
      setData(
        hookDataBannedUsers.data.map(i => {
          const userId = i.userId;
          const name = `${i.user.firstName} ${i.user.lastName}`;

          let location = '';
          if (i.user.filterSetting) {
            const userLocation = i.user.filterSetting.location;
            if (userLocation) {
              location = userLocation?.cityOrMunicipality;
              if (userLocation.hasProvince) {
                location += `, ${userLocation.province.name}`;
              }
            }
          }

          const numberOfReports = i.numberOfReports;

          const userType =
            i.user.userRole === 'homeowner' ? 'Homeowner' : 'Kazam';
          return {
            userId,
            name,
            location,
            numberOfReports,
            userType,
          } as TableData;
        }),
      );
    }
  }, [hookDataBannedUsers]);

  const onUnbanUserClickedHandler = async (rowData: TableData) => {
    try {
      await postUnbanUser.mutateAsync({userId: rowData.userId});

      systemLogEvent('active_users', {
        kazams:
          (hookDataSystemStatistics?.activeKazams || 0) +
          (authData?.user.userRole === 'kazam' ? 1 : 0),
        homeowners:
          (hookDataSystemStatistics?.activeHomeowners || 0) +
          (authData?.user.userRole === 'homeowner' ? 1 : 0),
      });

      systemLogEvent('system_statistics', {
        ...hookDataSystemStatistics,
      });

      presentToast({
        message: 'Unbanned successfully.',
        variant: 'success',
        position: 'bottom',
      });

      await refetchSystemStatistics();
    } catch (e) {
      console.log(e);
      presentToast({
        message: `Unexpected error was occurred.`,
        variant: 'error',
        position: 'bottom',
      });
    }
  };

  const manualRenderFn = (columnId: string, rowData: TableData) => {
    return (
      <div
        className="cursor-pointer"
        onClick={() => onUnbanUserClickedHandler(rowData)}>
        <Typography
          label="Unban"
          variant="f2"
          color={colorTheme.primary}
          align="left"
        />
      </div>
    );
  };

  return (
    <StyledAdminUsersTable>
      <AdminTable
        columns={columns}
        data={data}
        pageSize={10}
        pageNumber={pageNumber}
        pageCount={pageCount}
        showDebugView={false}
        onPageChange={onPageChangedHandler}
        manualRenderFn={manualRenderFn}
      />
    </StyledAdminUsersTable>
  );
};

export default AdminBannedUsersTable;
