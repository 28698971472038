import {useEffect, useState} from 'react';
import {Button, DurationInput, Input, TextArea, Toggle, Typography} from '../../../../core/components';
import {StyledWrapper} from './styled';
import {colorTheme} from '../../../../core/configs';
import {usePutAdminControl} from '../../../server/react-query';
import {useToast} from '../../../../core/components/toast';
import {
  AdminControl,
  AdminControlReqBody,
  AdminControlType,
  DurationContent,
} from '../../../server/types/admin-control.types';
import Divider from '../../../../core/components/divider/Divider';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { durationSchema } from '../../../schema/admin.schema';

interface SmsSettingsViewProps {
  data: AdminControl[];
}

const SmsSettingsItem = ({
  item,
  label,
  description,
}: {
  item?: AdminControl,
  label: string,
  description: string
}) => {
  const {presentToast} = useToast();

  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [smsContent, setSmsContent] = useState<string>();
  const putAdminControl = usePutAdminControl();

  const {
    control,
    getValues,
    reset,
    formState: {errors},
    handleSubmit,
  } = useForm<DurationContent>({
    resolver: yupResolver(durationSchema),
    defaultValues: {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
  });

  useEffect(() => {
    if (item) {
      setIsEnabled(item?.enabled)
      setSmsContent(item.text)
    }
  }, [item])

  const insertContent = (value: string) => {
    var currentContent = smsContent + value;
    setSmsContent(currentContent);
  };

  const submitForm = () => {
    handleSubmit(onSave)();
  };

  const onSave = () => {
    if (item) {
      const data: AdminControlReqBody = {
        type: item.type,
        enabled: isEnabled,
        content: JSON.stringify(getValues()),
        text: smsContent
      };
      
      onPutAdminControl(data);
    }
  };

  const handleToggle = (toggled: boolean) => {
    setIsEnabled(toggled)
    if (item) {
      const data: AdminControlReqBody = {
        type: item.type,
        enabled: toggled,
        content: JSON.stringify(getValues()),
        text: smsContent
      };
      
      onPutAdminControl(data);
    }
  }

  const onPutAdminControl = async (data: AdminControlReqBody) => {
    try {
      await putAdminControl.mutateAsync(data);
      presentToast({
        message: `Successfully saved.`,
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="pt-3 flex flex-row justify-between items-center">
        <div>
          <Typography
            label={label}
            variant="f3"
            color={isEnabled ? colorTheme.dark : colorTheme.lightGray}
          />
          <Typography
            label={description}
            variant="f1"
            color={
              isEnabled ? colorTheme.darkFaded : colorTheme.lightGray
            }
          />
        </div>
        <div>
          <Toggle value={isEnabled} onToggle={handleToggle} />
        </div>
      </div>

      {isEnabled && (
        <>
          <div className="pt-3">
            <div>
              <div className="pt-3 flex flex-row justify-between items-center">
                <TextArea
                  placeholder="Enter generic chat message"
                  rows={2}
                  maxRows={2}
                  autoresize
                  bordered
                  value={smsContent}
                  onChange={value => setSmsContent(value.currentTarget.value)}
                />
              </div>

              <div className="pt-3 flex flex-row justify-between items-center">
                <div className="flex space-x-4">
                  <div onClick={() => insertContent('<Kasambahay>')}>
                    <Typography
                      className="cursor-pointer"
                      label="Insert Kasambahay's first name"
                      variant="f2"
                      weight="semibold"
                      color={colorTheme.primary}
                    />
                  </div>

                  <div onClick={() => insertContent('<Sender>')}>
                    <Typography
                      className="cursor-pointer"
                      label="Insert Sender's first name"
                      variant="f2"
                      weight="semibold"
                      color={colorTheme.primary}
                    />
                  </div>
                </div>
              </div>

              <div className="pt-3 flex justify-end">
                <div style={{width: 'fit-content'}}>
                  <Button
                    label="Save"
                    color="primary"
                    onClick={submitForm}
                    isLoading={putAdminControl.isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
  
}

const SmsSettingsView = ({data}: SmsSettingsViewProps) => {
  const {presentToast} = useToast();

  const putAdminControl = usePutAdminControl();

  const [
    smsNotifInterval,
    setSmsNotifInterval,
  ] = useState<DurationContent>();
  
  useEffect(() => {
    if (data) {
      var smsNotifInterval = data.find(
        d => d.type === 'smsNotificationInterval',
      );

      if (smsNotifInterval) {
        var duration = JSON.parse(
          smsNotifInterval?.content.toLowerCase(),
        ) as DurationContent;
        setSmsNotifInterval(duration);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getAdminControl = (type: AdminControlType) => {
    return data.find(d => d.type === type);
  }

  const onSaveSmsNotifInterval = (value: DurationContent) => {
    const data: AdminControlReqBody = {
      type: 'smsNotificationInterval',
      enabled: true,
      content: JSON.stringify(value),
    };
    onPutAdminControl(data);
  };

  const onPutAdminControl = async (data: AdminControlReqBody) => {
    try {
      await putAdminControl.mutateAsync(data);
      presentToast({
        message: `Successfully saved.`,
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      console.log(e);
    }
  };

  return (
    <StyledWrapper>
      <Typography
        label="SMS SETTINGS"
        variant="f1"
        weight="semibold"
        color={colorTheme.black}
      />

      <div>
        <div className="flex flex-row justify-between items-center">
          <div>
            <Typography
              label="SMS Notification Interval Control"
              variant="f3"
              color={colorTheme.dark}
            />
            <Typography
              label="Set the interval of sending sms notifications. The default value is 1 day."
              variant="f1"
              color={colorTheme.darkFaded}
            />
          </div>
        </div>
        <div className="pt-3">
          <DurationInput
            duration={smsNotifInterval}
            onSubmit={onSaveSmsNotifInterval}
            isLoading={putAdminControl.isLoading}
          />
        </div>
      </div>
      <Divider />
      
      <SmsSettingsItem
        item={getAdminControl('newHomeownerChatSmsNotification')}
        label="Enable SMS notification for new conversation to kazam from homeowners"
        description="Set the generic SMS content the kazam will receive when the homeowner initiates a chat."
      />

      <Divider />

      <SmsSettingsItem
        item={getAdminControl('newAdminChatSmsNotification')}
        label="Enable SMS notification for new conversation to kazam from admin"
        description="Set the generic SMS content the kazam will receive when the admin initiates a chat."
      />

      <Divider />

      <SmsSettingsItem
        item={getAdminControl('newMessageToKazamSmsNotification')}
        label="Enable SMS notification for new message to kazam from existing conversations"
        description="Set the generic SMS content the kazam will receive when a new message is sent from existing convesations"
      />
      
    </StyledWrapper>
  );
};

export default SmsSettingsView;
