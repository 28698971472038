import { IVersionInfo } from './core/models/IVersionInfo';

export class KazamVersionInfo implements IVersionInfo {
  name = process.env.REACT_APP_NAME || "Kazam";
  version = process.env.REACT_APP_VERSION || "1.5.1";
  build = process.env.REACT_APP_BUILD || "1772";
  baseUrl = process.env.REACT_APP_BASE_URL || "@BASE_URL";
  target = process.env.REACT_APP_TARGET || "prod";
  hash = process.env.REACT_APP_HASH || "f810ec1d1403a4082a1a07f45bd3dfc65493cc3e";
  branch = process.env.REACT_APP_BRANCH || "release/v2-6";

  public toString(): string {
    return `${this.name} ${this.version} b${this.build}`;
  }
}

export const VersionInfo = new KazamVersionInfo();
