import {useState, useEffect} from 'react';
import {StyledSendOfferDialog} from './styled';
import {
  Button,
  Typography,
  Input,
  Checkbox,
  Select,
  Typehead,
} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {localization} from '../../../localization/Localization';
import {DropdownComponent, DropdownDate} from '@iskaiiii/react-dropdown-date';
import {PostJobOfferBody} from '../../../server/types/job-offer.types';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
import {jobOfferSchema} from '../../../schema/job-offer.schema';
import {usePostJobOffer} from '../../../server/react-query/useJobOffer';
import {KazamException, KazamRole, LocationResponse, UserResponse} from '../../../server/types';
import { useAuth } from '../../../hooks/useAuth';
import { getLivingArrangement, getLivingArrangementValue } from '../../../utils/job-offer.util';
import { endOfDay, startOfDay } from 'date-fns';
import { useGetLocations } from '../../../server/react-query';
import { BeatLoader } from 'react-spinners';
interface SendJobOfferDialogProps {
  channelId: string
  recipientId: string
  recipientSalary: number;
  onCancel: () => void;
  onCreated: () => void;
  onError?: (message: string) => void;
}

interface SelectedRoles {
  selected: boolean
  role: KazamRole
}

const SendJobOfferDialog = (props: SendJobOfferDialogProps) => {
  const {authData} = useAuth();
  const [rolesList, setRolesList] = useState<SelectedRoles[]>([]);
  const [selectedLocation, setselectedLocation] = useState<LocationResponse>();
  
  const {data: locations, isFetching} = useGetLocations();

  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    setError,
    formState: {errors},
  } = useForm<PostJobOfferBody>({
    resolver: yupResolver(jobOfferSchema),
    defaultValues: {
      channelId: props.channelId,
      recipientId: props.recipientId,
      livingArrangement: 'stayIn',
      startDate: new Date(),
    },
  });

  useEffect(() => {
    if (authData
      && authData.user
      && authData.user.filterSetting) {
        const fs = authData.user.filterSetting
        var roles = fs.kazamRoles.map(r => {
          return {
            selected: true,
            role: r
          } as SelectedRoles
        })
        setRolesList(roles)
        setValue('roles', roles.map(r => r.role.role.roleId))
        setValue('salary', props.recipientSalary ?? 0)

        setselectedLocation(fs?.location);
      }
  }, [])

  const formatDate = (date: any) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  };

  const submitForm = async () => {
    handleSubmit(handleCreate)();
  };

  const postJobOffer = usePostJobOffer();
  const handleCreate = async () => {
    console.log(getValues());
    
    try {
      await postJobOffer.mutateAsync(getValues());
      props.onCreated();
    } catch (e: any) {
      console.log('error', e);
      const exception = e.data as KazamException;
      if (e.status
        && e.status === 403
        && exception?.errorCode === 'SalaryLimitError') {
        setError('salary', {message: "Invalid value."});
      }
      props.onError &&
        props.onError(exception ? exception.error : 'Failed to add.');
    }
  };

  const onChangeRoles = (i: number, v: boolean) => {
    setRolesList(state => {
      let newList = state ?? [];
      newList[i].selected = v;

      let selectedRoleIds = newList.filter(r => r.selected).map(kz => kz.role.role.roleId)
      setValue('roles', selectedRoleIds)
      
      return newList;
    })
  }


  return (
    <StyledSendOfferDialog>
      <div className="flex flex-col header mb-2 items-center">
        <Typography
          label={localization.dlg_hdr_offer_details}
          variant="f3"
          weight="semibold"
          color={colorTheme.dark}
        />
      </div>

      <div>
        <Typography
          className="mb-3 text-center"
          label="Send an offer to the kasambahay by providing the details below."
          variant="f1"
          weight="normal"
          align="center"
          color={colorTheme.darkFaded}
        />
      </div>

      {isFetching ? (
        <div
          className="w-full flex justify-center items-center">
          <BeatLoader
            color={colorTheme.primary}
            loading={isFetching}
            margin={2}
            size={15}
          />
        </div>
      ) : (
        <>
          <div className="mb-2">
            <Controller
              control={control}
              name="startDate"
              render={({field: {value = '', onBlur, onChange}}) => (
                <>
                  <div className="mb-2">
                    <Typography
                      label="Start Date"
                      variant="f1"
                      weight="semibold"
                      color={colorTheme.darkFaded}
                    />
                  </div>
                  <div>
                    <div>
                      <DropdownDate
                        startDate={formatDate(startOfDay(new Date()))}
                        endDate={`${new Date().getFullYear() + 20}-12-31`}
                        selectedDate={formatDate(value)}
                        onDateChange={(v: any) => {
                          const date = new Date(v)
                          const dayToday = startOfDay(new Date())
                          if (date < dayToday){
                            onChange(dayToday)
                            props.onError && props.onError("Start date must not be a past date.")
                          } else {
                            onChange(v)
                          }
                        }}
                        options={{
                          yearReverse: false,
                        }}
                        order={[
                          DropdownComponent.month,
                          DropdownComponent.day,
                          DropdownComponent.year,
                        ]}
                      />
                    </div>
                  </div>

                  {errors.startDate?.message && (
                    <div
                      data-testid="error-msg-element"
                      style={{
                        marginTop: '0.25rem',
                        fontSize: '0.75rem',
                        lineHeight: '1.125rem',
                        color: colorTheme.danger,
                      }}>
                      {errors.startDate?.message}
                    </div>
                  )}
                </>
              )}
            />
          </div>

          <div className="mb-2">
            <Controller
              control={control}
              name="endDate"
              render={({field: {value = '', onBlur, onChange}}) => (
                <>
                  <div className="mb-2">
                    <Typography
                      label="End Date"
                      variant="f1"
                      weight="semibold"
                      color={colorTheme.darkFaded}
                    />
                  </div>
                  <div>
                    <div>
                      <DropdownDate
                        startDate={formatDate(startOfDay(new Date()))}
                        endDate={`${new Date().getFullYear() + 20}-12-31`}
                        selectedDate={formatDate(value)}
                        onDateChange={(v: any) => {
                          const date = endOfDay(new Date(v))
                          const dayToday = startOfDay(new Date())
                          if (date < dayToday){
                            props.onError && props.onError("End date must not be a past date.")
                            onChange(date)
                          } else {
                            onChange(date)
                          }
                        }}
                        options={{
                          yearReverse: false,
                        }}
                        order={[
                          DropdownComponent.month,
                          DropdownComponent.day,
                          DropdownComponent.year,
                        ]}
                      />
                    </div>
                  </div>
                  {errors.endDate?.message && (
                    <div
                      data-testid="error-msg-element"
                      style={{
                        marginTop: '0.25rem',
                        fontSize: '0.75rem',
                        lineHeight: '1.125rem',
                        color: colorTheme.danger,
                      }}>
                      {errors.endDate?.message}
                    </div>
                  )}
                </>
              )}
            />
          </div>

          {locations && locations.length > 0 &&
            <div className="mb-2">
              <Controller
                control={control}
                name="locationId"
                render={({field: {value = '', onBlur, onChange}}) => (
                  <Typehead
                    label="Hiring Address"
                    items={locations}
                    placeholder={localization.ph_search_loc}
                    onChange={sel => {
                      var item = sel && sel.length > 0 ? sel[0].locationId : '';
                      onChange(item);
                    }}
                    selected={selectedLocation}
                    error={errors.locationId?.message}
                  />
                )}
              />
            </div>
          }

          <div className="flex gap-2 mb-2 items-center">
            <div className="w-full">
              <Controller
                control={control}
                name="salary"
                render={({field: {value = '', onBlur, onChange}}) => (
                  <Input
                    label="Salary"
                    inputSize="large"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={errors.salary?.message}
                  />
                )}
              />
            </div>

            <div className="w-full">
              <Controller
                control={control}
                name="livingArrangement"
                render={({field: {value, onChange}}) => (
                  <Select
                    label="Living Arrangement"
                    items={['Stay-In', 'Stay-Out', 'Stay-In or Stay-Out']}
                    itemRenderer={(item, selected) => (
                      <Typography
                        label={item ?? ''}
                        variant="f1"
                        color={colorTheme.dark}
                        weight={selected ? 'semibold' : 'normal'}
                        className="flex-1"
                      />
                    )}
                    onChange={(v) => {
                      onChange(v ? getLivingArrangement(v) : null)
                    }}
                    value={getLivingArrangementValue(value)}
                    valueRenderer={value => value ?? ''}
                    error={errors.livingArrangement?.message}
                  />
                )}
              />
            </div>
          </div>

          <div className="mb-2">
            <div className="mb-2">
              <Typography
                label="Roles"
                variant="f1"
                weight="semibold"
                color={colorTheme.darkFaded}
              />
            </div>

            <div>
              {rolesList?.map((r, i) => {
                return (
                  <div key={i} className="mb-2 flex">
                    <Checkbox
                      checked={r.selected}
                      onChange={(v) => onChangeRoles(i, v)} />
                    <Typography
                      label={r.role.role.text}
                      variant="f1"
                      align="left"
                      color={colorTheme.dark}></Typography>
                  </div>
                )
              })}
            </div>
          </div>

          <div className="w-full flex flex-row space-x-2 pt-4">
            <Button
              label="Cancel"
              variant="outlined"
              className="flex-1"
              onClick={props.onCancel}
            />

            <div className="spacer" />

            <Button
              label="Send"
              color="primary"
              className="flex-1"
              onClick={submitForm}
              isLoading={postJobOffer.isLoading}
            />
          </div>
        </>
      )}
    </StyledSendOfferDialog>
  );
};

export default SendJobOfferDialog;
